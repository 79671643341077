<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/MaintenancesV2">{{'general.maintenance' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/MaintenancesV2/ServiceItems/Overview">{{'menu.serviceItems' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.details' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-maintenance-v2-navigation>
    </fh-maintenance-v2-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <form #form="ngForm">
            <fh-maintenance-service-item-details [formMode]="formMode" [serviceItem]="serviceItem" 
                [form]="form" (update)="onSave()">
            </fh-maintenance-service-item-details>
        </form>
    </div>
</fh-permission-handler>