import { ServiceInterval } from "./serviceItem.model";

export class WorkOrder {
  id: number;
  accountId: number;
  deviceId: number;
  assetId: number;
  assetName: string;
  resellerId: number;
  resellerDescription: string;
  companyName: string;
  serviceDate: Date;
  odometer: number;
  engineHours: number;
  completed: boolean;
  totalCost: number;
  workOrderItems: WorkOrderItem[];
}

export class WorkOrderItem {
  workOrderId: number;
  serviceItemId: number;
  serviceDate: Date;
  serviceInterval: ServiceInterval;
  serviceItemName: string;
  description: string;
  quantity: number;
  itemPrice: number;
  laborCost: number;
  totalCost: number;
  recurrence: string;
  planned: boolean;
  exists: boolean;
}
