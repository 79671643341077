import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, OnInit } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { ServiceItem } from "app/models/serviceItem.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { ServiceItemService } from "app/services/serviceitem/serviceItem.service";
import { MaintenanceServiceItemDetailsComponent } from "app/views/shared/usercontrols/serviceItemDetails.component";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "fh-fh-maintenance-service-item-details",
    templateUrl: "serviceItemDetails.template.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceServiceItemDetailsViewComponent implements OnInit {
    @ViewChild(MaintenanceServiceItemDetailsComponent, { static: true }) serviceItemDetails: MaintenanceServiceItemDetailsComponent;

    formMode = FormMode.read;
    permissions: {};

    serviceItem = new ServiceItem;  
    serviceItemId;

    permissionName = "MaintenanceTypes_View";

    constructor(
        private cd: ChangeDetectorRef, 
        private authenticationService: AuthenticationService, 
        private serviceItemService: ServiceItemService,
        private route: ActivatedRoute
    ) {
        this.permissions = this.authenticationService.permissions;
    }

    ngOnInit() : void {
        this.route.params.subscribe((params) => {
            this.serviceItemId = params["id"];
            this.serviceItemDetails.serviceItemId = this.serviceItemId;

            this.serviceItemDetails.loading = true;

            this.serviceItemService.getServiceItemById(this.serviceItemId).subscribe(serviceItem => {
              this.serviceItem = serviceItem;
              this.serviceItemDetails.loading = false;
              this.cd.markForCheck();
            });
        });
    }

    onSave() {
        this.serviceItemDetails.loading = true;

        this.serviceItemService.updateServiceItem(this.serviceItemId, this.serviceItem).subscribe(result => {
  
            this.serviceItemDetails.error = null;
            this.serviceItemDetails.success = {
                statusText: "Success",
                success: "Service item is successfully updated."
            };
  
            this.serviceItemDetails.loading = false;
            this.cd.markForCheck();
  
            this.serviceItemDetails.setFormMode(FormMode.read);
        }, error => {
            this.serviceItemDetails.loading = false;
            this.serviceItemDetails.success = null;
            this.serviceItemDetails.error = error;
            this.cd.markForCheck();
        });
    }
}