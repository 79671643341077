<fh-permission-handler [permissionName]="permissionName">
    <div class="row wrapper border-bottom white-bg page-heading">
        <fh-home-navigation> </fh-home-navigation>

        <div class="flex-container">
            <div [hidden]="!permissions['Resellers_View']" class="col-lg-3">
                <div class="form-group input-group" style="margin-bottom: 6px; display: block">
                    <fh-reseller-input
                        name="reseller"
                        #resellerVar="ngModel"
                        (onChanged)="resellerChanged($event)"
                        required
                        placeholder="{{ 'general.chooseReseller' | translate}}"
                        [(ngModel)]="selectedResellerId"
                    >
                    </fh-reseller-input>
                </div>
            </div>
            <div [hidden]="!permissions['Accounts_View']" class="col-lg-3">
                <div class="form-group input-group" style="margin-bottom: 6px; display: block">
                    <fh-account-input
                        name="account"
                        #accountVar="ngModel"
                        (onChanged)="accountChanged($event)"
                        required
                        [selectedResellerId]="selectedResellerId"
                        [showEmptyAccountsButton]="false"
                        placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                        [(ngModel)]="selectedAccountId"
                    >
                    </fh-account-input>
                </div>
            </div>
            <div [hidden]="!permissions['AssetGroups_View']" class="col-lg-3">
                <div class="form-group" style="margin-bottom: 6px">
                    <fh-asset-group-input
                        name="assetGroups"
                        #assetGroupVar="ngModel"
                        required
                        [selectedAccountId]="selectedAccountId"
                        [tableInline]="false"
                        [multiple]="false"
                        [autoSelect]="false"
                        (onChanged)="filterMarkers(true); dateChanged();"
                        placeholder="{{ (selectedAccountId? 'general.chooseAssetGroups' : 'general.firstChooseAccount') | translate}}"
                        [(ngModel)]="selectedAssetGroups"
                    >
                    </fh-asset-group-input>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group input-group" style="margin-bottom: 6px">
                    <span class="input-group-prepend">
                        <i class="fa fa-fw fa-search"></i>
                    </span>
                    <input
                        style="width: 100%"
                        type="text"
                        min="0"
                        class="form-control"
                        placeholder="{{ 'general.searchOnDeviceOrImei' | translate}}"
                        (input)="filterMarkers(true)"
                        [(ngModel)]="search"
                    />
                </div>
            </div>
            <div class="col-flex d-flex flex-fill d-none d-sm-block">
                <div class="form-group input-group" style="margin-bottom: 6px">
                    <input
                        placeholder="Daterangepicker"
                        style="width: 100%; margin-top: 0px; width: 100%; margin-bottom: -5px"
                        placeholder="Datepicker"
                        type="text"
                        [bsConfig]="dpConfig"
                        class="form-control"
                        bsDaterangepicker
                        (onHidden)="dateChanged()"
                        [(ngModel)]="daterangepickerModel"
                    />
                    <span class="input-group-calendar-datatables">
                        <i class="fa fa-calendar-alt"></i>
                    </span>
                    <div style="clear: both"></div>
                </div>
            </div>
            <div class="col-flex d-flex flex-fill d-none d-sm-block">
                <div class="form-group input-group" style="margin-bottom: 6px">
                    <span class="input-group-prepend">
                        <i class="fa fa-fw fa-map-marker-alt"></i>
                    </span>
                    <ng-select
                        class="form-control"
                        name="displayEvents"
                        (change)="filterMarkers(true)"
                        placeholder="{{ 'general.deviceState' | translate }}"
                        [(ngModel)]="status"
                        style="width: 100%"
                    >
                        <ng-option [value]="0">
                            <i class="fa fa-fw fa-map-marker-alt eye-active black"></i>
                            {{ 'general.deviceState_0' | translate}} ({{getDeviceCount(0)}})
                        </ng-option>
                        <ng-option [value]="1">
                            <i class="fa fa-fw fa-map-marker-alt eye-active green"></i>
                            {{ 'general.deviceState_1' | translate}} ({{getDeviceCount(1)}})
                        </ng-option>
                        <ng-option [value]="2">
                            <i class="fa fa-fw fa-map-marker-alt eye-active red"></i>
                            {{ 'general.deviceState_2' | translate}} ({{getDeviceCount(2)}})
                        </ng-option>
                        <ng-option [value]="3">
                            <i class="fa fa-fw fa-map-marker-alt eye-active orange"></i>
                            {{ 'general.deviceState_3' | translate}} ({{getDeviceCount(3)}})
                        </ng-option>
                        <ng-option [value]="4">
                            <i class="fa fa-fw fa-map-marker-alt eye-active blue"></i>
                            {{ 'general.deviceState_4' | translate}} ({{getDeviceCount(4)}})
                        </ng-option>
                        <ng-option [value]="5">
                            <i class="fa fa-fw fa-map-marker-alt eye-active black"></i>
                            {{ 'general.deviceState_5' | translate}} ({{getDeviceCount(5)}})
                        </ng-option>
                        <ng-option [value]="6">
                            <i class="fa fa-fw fa-location-crosshairs-slash eye-active violet"></i>
                            {{ 'general.deviceState_6' | translate}} ({{getDeviceCount(6)}})
                        </ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="col-flex flex-fill d-none d-sm-block" *ngIf="permissions['Entities_DeviceTypes']">
                <div class="form-group input-group" style="margin-bottom: 6px">
                    <span class="input-group-prepend">
                        <i class="fa fa-fw fa-router"></i>
                    </span>
                    <ng-select
                        class="form-control"
                        name="displayEvents"
                        (change)="filterMarkers(true)"
                        placeholder="{{ 'general.chooseDeviceType' | translate }}  ({{ deviceTypeOptions.length }})"
                        [loading]="loadingDeviceTypes"
                        [(ngModel)]="deviceType"
                        style="width: 100%"
                    >
                        <ng-option *ngFor="let deviceType of deviceTypeOptions" [value]="deviceType.id">
                            {{ deviceType.value }}</ng-option
                        >
                    </ng-select>
                </div>
            </div>

            <div style="clear: both"></div>
        </div>

        <div class="col-lg-12" style="padding: 1px 0 0 0">
            <ngx-loading [show]="loadingDevices"></ngx-loading>
            <fh-leaflet-map
                [geofences]="geofences"
                [locations]="locationData"
                [useClustering]="true"
                [geofenceEnabled]="false"
                [isSpinning]="isSpinning"
                [height]="mapHeight"
                [allowBookmarks]="false"
            >
            </fh-leaflet-map>
        </div>
    </div>

    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [error]="error"></fh-error>
            </div>
        </div>

        <div class="row" *ngIf="announcements.length > 0" [@slideInOut]>
            <div class="col-lg-12">
                <ng-container *ngFor="let announcement of announcements">
                    <div
                        *ngIf="announcement.culture == culture || announcement.type == 2"
                        class="alert shadow"
                        [ngClass]="'alert-' + announcement.severity"
                    >
                        <span>
                            <p *ngIf="announcement.type == 1" style="font-size: 15px">
                                <strong>
                                    <i class="fa fa-fw fa-bullhorn fa-fw"></i>
                                    {{ 'general.serviceAnnouncement' | translate }}
                                </strong>
                            </p>

                            <p *ngIf="announcement.type == 2" style="font-size: 15px">
                                <strong>
                                    <i class="fa fa-fw fa-exclamation-triangle"></i>
                                    {{ 'general.clientAnnouncement' | translate }}
                                </strong>
                            </p>
                        </span>
                        <span>{{ announcement.message }}</span>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="ibox" *ngIf="permissions['Platform_IsReseller'] || isImpersonated" [@slideInOut]>
                    <div class="ibox-content slicker flex-container" style="min-height: 32px; border: 0">
                        <div class="d-flex" style="flex: 2 1 0">
                            <div style="padding: 10px" *ngIf="isImpersonated" class="d-none d-md-inline-flex">
                                {{ 'general.impersonated' | translate }} {{ impersonationId }}
                            </div>
                            <div class="float-right margin-left" style="padding: 10px" *ngIf="isImpersonated">
                                <a (click)="stopImpersonation()"
                                    ><i class="fas fa-fw fa-user-secret"></i> {{ 'general.stopImpersonation' | translate
                                    }}
                                </a>
                            </div>

                            <div
                                class="input-group"
                                style="width: 100%; border: 0; background: none !important"
                                *ngIf="!isImpersonated"
                            >
                                <input
                                    [typeahead]="autoComplete$"
                                    [(ngModel)]="asyncSelected"
                                    [ngModelOptions]="{standalone: true}"
                                    (typeaheadLoading)="changeTypeaheadLoading($event)"
                                    (typeaheadOnSelect)="typeaheadOnSelect($event)"
                                    [typeaheadWaitMs]="1000"
                                    typeaheadGroupField="groupname"
                                    [typeaheadOptionsLimit]="50"
                                    [typeaheadScrollable]="true"
                                    [typeaheadOptionsInScrollableView]="10"
                                    typeaheadOptionField="name"
                                    placeholder="{{'topnav.searchForUser' | translate }}"
                                    class="form-control typeahead"
                                    style="background: none"
                                />

                                <div class="input-group-btn">
                                    <button
                                        class="btn btn-default"
                                        style="border: none; background: none; color: #fff"
                                        type="submit"
                                    >
                                        <span *ngIf="typeaheadLoading">
                                            <i class="fa fa-spinner fa-spin fa-hourglass-start"></i>
                                        </span>
                                        <span *ngIf="!typeaheadLoading">
                                            <i class="fa fa-search"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div style="clear: both"></div>
                        </div>
                        <div class="d-flex flex align-items-center" style="flex: 1 1 170px">
                            <div class="form-group input-group" style="margin-bottom: 0px" title="Filter on 0 values">
                                <span
                                    id="checkBox11"
                                    class="input-group-prepend hand"
                                    title="Filter on 0 values"
                                    (click)="flipIncludeAssetsOtherAccounts(); dateChanged()"
                                >
                                    <i
                                        id="checkBox_11"
                                        class="auto_checkbox fa fa-fw"
                                        style="text-align: center"
                                        [ngClass]="includeAssetsOtherAccounts ? 'fa-check' : 'fa-times'"
                                        title="Filter on 0 values"
                                        [style.color]="includeAssetsOtherAccounts ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"
                                    ></i>
                                </span>
                                <input
                                    name="checkboxName_11"
                                    id="checkboxName_11"
                                    style="width: 100%"
                                    placeholder="{{'general.includeAssetsOtherAccounts' | translate }}"
                                    disabled="disabled"
                                    class="form-control"
                                />
                            </div>
                        </div>
                        <div style="clear: both"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="position: relative">
            <ngx-loading [show]="loadingUtilization"></ngx-loading>

            <div class="col-6 col-md-4 col-lg-2">
                <div class="ibox hand">
                    <div
                        class="kpi-item"
                        style="position: relative"
                        [routerLink]="['/Devices/Overview/']"
                        title="{{'general.activeAssetsHover' | translate:{ value: daterangepickerModel[0].toLocaleString() + ' - ' + daterangepickerModel[1].toLocaleString() } }}"
                    >
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-car"></i>
                            {{ accountUtilization?.assetCountWithTrips ?? 0 }}<span
                                *ngIf="accountUtilization?.assetCountWithTrips != accountUtilization?.assetCount"
                            >
                                / {{ accountUtilization?.assetCount ?? 0 }}</span
                            >
                        </h3>
                        <div>{{'general.activeAssets' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
                <div class="ibox">
                    <div
                        class="kpi-item"
                        style="position: relative"
                        title="{{'general.distancePrivate' | translate }} {{ accountUtilization?.totalDistancePrivate ?
                    accountUtilization?.totalDistancePrivate.toLocaleString() : 0 }} {{translatedKm}}"
                    >
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-route"></i>
                            {{ accountUtilization?.totalDistance ? accountUtilization?.totalDistance.toLocaleString() :
                            0 }} {{translatedKm}}
                        </h3>
                        <div>{{'general.distance' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
                <div class="ibox hand">
                    <div
                        class="kpi-item"
                        style="position: relative"
                        [routerLink]="'/Devices/Trips'"
                        [queryParams]="{start: daterangepickerModel[0], end: daterangepickerModel[1], orderBy: 'maxSpeed', accountId: includeAssetsOtherAccounts ? null : selectedAccountId}"
                    >
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-route"></i>
                            {{ accountUtilization?.tripCount ? accountUtilization?.tripCount?.toLocaleString() : 0 }}
                        </h3>
                        <div>{{'general.trips' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2" *ngIf="permissions['FleetManagement_DeviceUtilization']">
                <div class="ibox">
                    <div class="kpi-item" style="position: relative">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-clock"></i>
                            {{ accountUtilization?.totalWorkingHoursInSeconds ?
                            actualRound(accountUtilization?.totalWorkingHoursInSeconds / 3600, 0).toLocaleString() : 0
                            }} h
                        </h3>
                        <div>{{'general.workingHours' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2" *ngIf="permissions['FleetManagement_DeviceUtilization']">
                <div class="ibox">
                    <div class="kpi-item" style="position: relative">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-clock"></i>
                            {{ accountUtilization?.totalPureDrivingDurationInSeconds ?
                            actualRound(accountUtilization?.totalPureDrivingDurationInSeconds / 3600,
                            0).toLocaleString() : 0 }} h
                        </h3>
                        <div>{{'general.pureDriving' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2" *ngIf="permissions['FleetManagement_DeviceUtilization']">
                <div class="ibox">
                    <div class="kpi-item" style="position: relative">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-snooze"></i>
                            {{ accountUtilization?.totalIdlingDurationInSeconds ?
                            actualRound(accountUtilization?.totalIdlingDurationInSeconds / 3600, 0).toLocaleString() : 0
                            }} h
                        </h3>
                        <div>{{'general.idling' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2" *ngIf="permissions['FleetManagement_DeviceUtilization']">
                <div class="ibox">
                    <div class="kpi-item" style="position: relative">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-snooze"></i>
                            {{ accountUtilization?.totalIdlingDurationInSeconds ? actualRound(((
                            (accountUtilization?.totalIdlingDurationInSeconds /
                            accountUtilization?.totalWorkingHoursInSeconds) * 100)),1)?.toLocaleString() : 0 }} %
                        </h3>
                        <div title="Idling time / Engine on time">{{'general.idlingPercentage' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
                <div class="ibox hand">
                    <div
                        class="kpi-item"
                        style="position: relative"
                        [routerLink]="'/Devices/Trips'"
                        [queryParams]="{start: daterangepickerModel[0], end: daterangepickerModel[1], orderBy: 'maxSpeed', accountId: includeAssetsOtherAccounts ? null : selectedAccountId}"
                    >
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-tachometer-fastest"></i>
                            {{ accountUtilization?.maxSpeed ?
                            actualRound((accountUtilization?.maxSpeed),0)?.toLocaleString() : 0 }} {{translatedKmh}}
                        </h3>
                        <div>{{'general.maxSpeed' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
                <div class="ibox">
                    <div class="kpi-item" style="position: relative">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-gas-pump"></i>
                            {{ accountUtilization?.totalConsumptionMixed ?
                            actualRound((accountUtilization?.totalConsumptionMixed),0)?.toLocaleString() : 0 }} L
                        </h3>
                        <div>{{'general.totalConsumptionMixed' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
                <div class="ibox">
                    <div class="kpi-item" style="position: relative">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-cloud"></i>
                            {{ accountUtilization?.totalEmissionCO2 ?
                            actualRound((accountUtilization?.totalEmissionCO2),0)?.toLocaleString() : 0 }} kg
                        </h3>
                        <div>{{'general.totalEmissionCO2' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2" *ngIf="permissions['FleetManagement_DeviceUtilization']">
                <div class="ibox">
                    <div class="kpi-item" style="position: relative">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-route"></i>
                            {{ accountUtilization?.totalDistance && accountUtilization?.tripCount ?
                            (actualRound(accountUtilization?.totalDistance /
                            accountUtilization?.tripCount,1)).toLocaleString() : 0 }} {{translatedKm}}
                        </h3>
                        <div>{{'general.avgTripDistance' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2" *ngIf="permissions['FleetManagement_DeviceUtilization']">
                <div class="ibox hand">
                    <div class="kpi-item" style="position: relative" [routerLink]="'/Dashboard/Utilization'">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-percent"></i>
                            {{ accountUtilization?.totalWorkingHoursInSeconds ? actualRound(((
                            (accountUtilization?.totalWorkingHoursInSeconds /
                            accountUtilization?.totalDurationInSeconds) * 100)),1)?.toLocaleString() : 0 }} %
                        </h3>
                        <div title="Engine on time / Total duration in timeselection">
                            {{'general.utilization' | translate }}
                        </div>
                    </div>
                </div>
            </div>

            <div style="clear: both"></div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-route"></i> {{'general.chartDistance' | translate }}</h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart
                            *ngIf="chartDistance"
                            [runOutsideAngular]="true"
                            style="display: block; width: 100%; height: 214px"
                            [Highcharts]="Highcharts"
                            [options]="chartDistance"
                        >
                        </highcharts-chart>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-engine"></i> {{'general.chartEngineHours' | translate }}</h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingEngineHours"></ngx-loading>

                        <highcharts-chart
                            *ngIf="chartEngineHours"
                            [runOutsideAngular]="true"
                            style="display: block; width: 100%; height: 214px"
                            [Highcharts]="Highcharts"
                            [options]="chartEngineHours"
                        >
                        </highcharts-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div
                [ngClass]="hasFeature('enableBetaMaintenance') ? 'col-lg-3' : 'col-lg-4'"
                *ngIf="accountUtilization?.assetCount > 5"
            >
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-car"></i> {{'general.communicatingAssets' | translate }}</h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart
                            *ngIf="chartActiveDevices"
                            [runOutsideAngular]="true"
                            style="display: block; width: 100%; height: 214px"
                            [Highcharts]="Highcharts"
                            [options]="chartActiveDevices"
                        ></highcharts-chart>
                    </div>
                </div>
            </div>
            <div
                [ngClass]="(accountUtilization?.assetCount > 5 && !hasFeature('enableBetaMaintenance')) ||  (accountUtilization?.assetCount < 5 && hasFeature('enableBetaMaintenance')) ? 'col-lg-4' : (accountUtilization?.assetCount > 5 && hasFeature('enableBetaMaintenance')) ? 'col-lg-3' : 'col-lg-6'"
            >
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-car"></i> {{'general.activityBreakdown' | translate }}
                            ({{'general.hours' | translate}})
                        </h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart
                            *ngIf="chartActivity"
                            [runOutsideAngular]="true"
                            style="display: block; width: 100%; height: 214px"
                            [Highcharts]="Highcharts"
                            [options]="chartActivity"
                        ></highcharts-chart>
                    </div>
                </div>
            </div>
            <div
                [ngClass]="(accountUtilization?.assetCount > 5 && !hasFeature('enableBetaMaintenance')) ||  (accountUtilization?.assetCount < 5 && hasFeature('enableBetaMaintenance')) ? 'col-lg-4' : (accountUtilization?.assetCount > 5 && hasFeature('enableBetaMaintenance')) ? 'col-lg-3' : 'col-lg-6'"
            >
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fw fa-comment-alt-dots"></i>
                            {{'general.locationCount' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart
                            *ngIf="chartLocationCount"
                            [runOutsideAngular]="true"
                            style="display: block; width: 100%; height: 214px"
                            [Highcharts]="Highcharts"
                            [options]="chartLocationCount"
                        ></highcharts-chart>
                    </div>
                </div>
            </div>

            <div
                *ngIf="hasFeature('enableBetaMaintenance')"
                [ngClass]="[accountUtilization?.assetCount > 5 ? 'col-lg-3' : 'col-lg-4']"
            >
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fa fa-fw fa-clock"></i> {{'general.maintenanceStatus' | translate }}</h5>
                    </div>

                    <div class="ibox-content slick" style="min-height: 224px">
                        <table *ngIf="maintenanceStatus" style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td>{{'general.healthy' | translate }}</td>
                                    <td style="text-align: right">
                                        <span class="badge badge-success" style="min-width: 70px"
                                            >{{ maintenanceStatus.healthy }}</span
                                        >
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{'general.inWorkshop' | translate }}</td>
                                    <td style="text-align: right">
                                        <span class="badge badge-primary" style="min-width: 70px"
                                            >{{ maintenanceStatus.inWorkshop }}</span
                                        >
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{'general.dueSoon' | translate }}</td>
                                    <td style="text-align: right">
                                        <span class="badge badge-warning" style="min-width: 70px"
                                            >{{ maintenanceStatus.dueSoon }}</span
                                        >
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{'general.overdue' | translate }}</td>
                                    <td style="text-align: right">
                                        <span class="badge badge-danger" style="min-width: 70px"
                                            >{{ maintenanceStatus.overdue }}</span
                                        >
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>
