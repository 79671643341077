<a
    *ngIf="asset?.value?.trips?.length === 0"
    class="fleet-overview-date"
    style="display: flex; position: relative; background: white"
>
    <ngx-loading [show]="asset.value.loading"></ngx-loading>
    {{'general.noDataFound' | translate}}
</a>

<ng-container *ngFor="let trip of renderTrips(asset?.value?.trips); let first = index;">
    <a *ngIf="isNewDate(trip)" style="justify-content: space-between" class="fleet-overview-date">
        {{ trip.beginDateTime | amDateFormat:'dddd LL' }}
        <span class="hand">
            <ng-container *ngIf="isShowingMultiple(asset.value.trips, trip.beginDateTime) as active; else inactive;">
                <i
                    (click)="$event.stopPropagation?.(); openMultipleTrips(asset.value.trips, trip.beginDateTime, false)"
                    style="
                        align-items: center;
                        min-height: 25px;
                        display: flex;
                        justify-content: center;
                        min-width: 20px;
                        padding-right: 2.5px;
                    "
                    class="fa fa-fw fa-eye eye-active green"
                ></i>
            </ng-container>
            <ng-template #inactive>
                <i
                    (click)="$event.stopPropagation?.(); openMultipleTrips(asset.value.trips, trip.beginDateTime, true)"
                    style="
                        align-items: center;
                        min-height: 25px;
                        display: flex;
                        justify-content: center;
                        min-width: 20px;
                        padding-right: 2.5px;
                    "
                    class="fa fa-fw fa-eye eye-active"
                ></i>
            </ng-template>
        </span>
    </a>

    <ng-container *ngIf="trip.isStop === true; else tripDisplay">
        <div title="{{trip.beginDateTime | amDateFormat:'ll HH:mm'}} - {{trip.endDateTime | amDateFormat:'ll HH:mm'}}">
            <a
                (click)="openStop(trip, trip.ident, asset)"
                class="fleet-overview-item fleet-overview-stop fontColor"
                [class.fleet-overview-no-content]="trip.hasEpisodes !== true"
            >
                <i class="fa fa-fw fa-hourglass-half fontColor" style="margin-right: 15px"></i>
                <span class="fontColor" style="display: flex; flex: auto; font-weight: 500; font-size: 13px"
                    >{{trip.durationHumanized}}</span
                >
                <span
                    *ngIf="trip.hasEpisodes === true"
                    class="fontColor fa arrow"
                    [ngClass]="trip.isOpen === true ? 'fa-rotate-270' : 'fa-flip-horizontal'"
                    style="margin: 0px 7.5px"
                >
                </span>
            </a>
        </div>

        <ul *ngIf="trip.isOpen === true" style="position: relative; padding: 0; margin: 0; background: white">
            <ng-container *ngIf="trip.loading === true; else episodeContent">
                <li style="display: flex; min-height: 50px; align-items: center; font-weight: 600; padding: 0px 20px">
                    <ngx-loading [show]="true"></ngx-loading>
                    {{'general.loadingDetails' | translate}}
                </li>
            </ng-container>
            <ng-template #episodeContent>
                <li style="display: flex" *ngFor="let event of trip.episodes;">
                    <i
                        style="background-color: rgb(240, 240, 240); margin-right: 5px"
                        class="fas fo-icon fa-lg fa-fw"
                        [ngClass]="event?.icon"
                        [style.color]="event?.iconFontColor"
                    ></i>
                    <div class="fontColor fo-navlabel">
                        <span class="fo-item-heading"> {{ event.description }} </span>
                        <span class="fo-content">
                            {{ event.duration }} at {{ event.startUtc | amDateFormat: 'HH:mm' }} - {{ event.endUtc |
                            amDateFormat: 'HH:mm' }}
                        </span>
                    </div>
                </li>
            </ng-template>
        </ul>
    </ng-container>
    <ng-template #tripDisplay>
        <a
            (click)="openTrip(trip, trip.ident)"
            class="fleet-overview-item"
            [class.calibrationTrip]="trip.tripMethod === 5"
            [ngStyle]="{'border-left': '7px solid ' + colorArray[(trip.ident) % colorArray.length]}"
        >
            <span
                class="fontColor"
                style="margin-right: 15px; font-weight: 600"
                [ngStyle]="{'color': colorArray[(trip.ident) % colorArray.length]}"
                >{{trip.ident}}</span
            >
            <span
                class="fontColor"
                style="display: flex; flex-direction: column; flex: auto; overflow: hidden"
                [class.activeTrip]="trip.tripType === 3 || trip.tripType === 4"
            >
                <span class="fo-item-heading">
                    {{ trip.beginDateTime | amDateFormat:'HH:mm' }} / {{ actualRound(trip.segmentDistanceInKilometers,
                    2)?.toLocaleString() }} {{ 'general.km' | translate }}

                    <span style="font-weight: 300; font-size: 11px" *ngIf="driversMap.get(trip.driverId) as driver;">
                        | {{ driver.name }}
                    </span>
                </span>
                <span class="fo-content">
                    {{trip.durationHumanized}} {{trip.endAddressFull ? '/ ' + trip.endAddressFull : ''}}
                </span>
            </span>
            <span
                class="fontColor fa arrow"
                [ngClass]="trip.isOpen === true ? 'fa-rotate-270' : 'fa-flip-horizontal'"
                style="margin: 0px 7.5px"
            ></span>
            <i
                (click)="$event.stopPropagation?.(); changeTripVisibility(trip, trip.ident)"
                [class.green]="trip.plotted === true"
                style="
                    align-items: center;
                    min-height: 25px;
                    display: flex;
                    justify-content: center;
                    min-width: 20px;
                    padding-right: 2.5px;
                "
                class="fa fa-fw fa-eye eye-active fontColor"
            ></i>
        </a>

        <ul *ngIf="trip.isOpen === true" style="position: relative; padding: 0; margin: 0; background: white">
            <ng-container *ngIf="trip.loading === true; else tripContent">
                <li style="display: flex; min-height: 50px; align-items: center; font-weight: 600; padding: 0px 20px">
                    <ngx-loading [show]="true"></ngx-loading>
                    {{'general.loadingDetails' | translate}}
                </li>
            </ng-container>
            <ng-template #tripContent>
                <!-- Trip end location -->
                <li style="display: flex" class="hand" (click)="displayLocation(asset, trip.endLocation)">
                    <i
                        style="background: black; margin-right: 5px; color: white; border-radius: 0 10px 0 0"
                        class="fo-icon fas fa-lg fa-fw fa-flag-checkered"
                    ></i>
                    <div class="fontColor fo-navlabel">
                        <span class="fo-item-heading"> {{ trip.endDateTime | amDateFormat:'HH:mm' }} </span>
                        <span class="fo-content">
                            {{'general.endLocation' | translate}} {{ trip.endAddressFull }}
                        </span>
                    </div>
                </li>

                <!-- Trip events -->
                <li
                    style="display: flex"
                    class="hand"
                    (click)="displayLocation(asset, event.location, event.geofence, event)"
                    *ngFor="let event of trip.concatenatedEvents; let i = index;"
                >
                    <i
                        style="background-color: rgb(240, 240, 240); margin-right: 5px"
                        class="fas fo-icon fa-lg fa-fw"
                        [ngClass]="event?.icon"
                        [style.color]="event?.iconFontColor"
                    ></i>
                    <div class="fontColor fo-navlabel">
                        <span class="fo-item-heading"> {{event.header }} </span>
                        <span [title]="event.description" class="fo-content" [innerHTML]="event.description"> </span>
                    </div>
                </li>

                <li style="display: flex" class="hand" *ngIf="trip.concatenatedEvents.length == 0">
                    <i
                        style="background-color: rgb(240, 240, 240); margin-right: 5px"
                        class="fas fo-icon fa-lg fa-fw fa-question-circle"
                    ></i>
                    <div class="fontColor fo-navlabel">
                        <span class="fo-content"> {{'general.noDataFound' | translate}} </span>
                    </div>
                </li>

                <!-- Trip start location -->
                <li style="display: flex" class="hand" (click)="displayLocation(asset, trip.beginLocation)">
                    <i
                        style="background: green; margin-right: 5px; color: white; border-radius: 0 0 10px 0"
                        class="fo-icon fas fa-lg fa-fw fa-clock"
                    ></i>
                    <div class="fontColor fo-navlabel">
                        <span class="fo-item-heading"> {{ trip.beginDateTime | amDateFormat:'HH:mm' }} </span>
                        <span class="fo-content">
                            {{'general.startLocation' | translate}} {{ trip.beginAddressFull }}
                        </span>
                    </div>
                </li>

                <!-- Bottom row -->
                <li *ngIf="trip.displayChart" style="display: flex; min-height: 55px">
                    <highcharts-chart
                        [runOutsideAngular]="true"
                        style="display: block; width: 100%; height: 220px"
                        [Highcharts]="Highcharts"
                        [options]="trip.speedChart"
                    >
                    </highcharts-chart>
                </li>

                <!-- Chart -->
                <li style="display: flex; min-height: 55px">
                    <div
                        style="
                            display: flex;
                            flex-direction: column;
                            padding: 0 10px;
                            overflow: hidden;
                            justify-content: center;
                        "
                        class="fontColor nav-label"
                    >
                        <span class="fo-item-heading"> {{'general.maxSpeed' | translate}}: </span>
                        <span class="fo-content"> {{ actualRound(trip.maxSpeed,0) }} km/u </span>
                    </div>

                    <button
                        *ngIf="isPlayingTrip !== true; else resumeButton"
                        class="btn fleet-overview-play-button"
                        style="width: 50px; margin-right: 2px"
                        title="{{ 'general.playTrip' | translate }}"
                        (click)="playTrip(trip)"
                    >
                        <i class="fa fa-fw fa-play"></i>
                    </button>

                    <ng-template #resumeButton>
                        <button
                            class="btn fleet-overview-play-button"
                            title="{{ 'general.stop' | translate }}"
                            style="width: 50px; margin-right: 2px"
                            (click)="stopTrip()"
                        >
                            <i class="fa fa-fw fa-stop"></i>
                        </button>
                    </ng-template>

                    <button
                        class="btn fleet-overview-play-button"
                        title="{{ 'general.displayChart' | translate }}"
                        style="width: 50px; margin-left: 2px; margin-right: 2px"
                        (click)="flipChart(trip)"
                    >
                        <i class="fa fa-fw fa-chart-line"></i>
                    </button>

                    <button
                        class="btn fleet-overview-play-button"
                        title="{{ 'general.displayChart' | translate }}"
                        style="width: 50px; margin-left: 2px"
                        (click)="flipLocations(trip)"
                    >
                        <i class="fa fa-fw fa-location-pin"></i>
                    </button>
                </li>
            </ng-template>
        </ul>
    </ng-template>
</ng-container>
