<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.trips' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-device-navigation></fh-device-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success" [warning]="warning"></fh-error>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                    </div>
                    <div
                        class="ibox-content slick_toppadding"
                        style="position: relative"
                        *ngIf="languageLoaded && queryParametersLoaded"
                    >
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div id="dynamicFilters" class="flex-container">
                            <div class="col-lg-6">
                                <div class="form-group" style="margin-bottom: 6px">
                                    <fh-reseller-input
                                        name="reseller"
                                        #resellerVar="ngModel"
                                        (onChanged)="resellerChanged($event)"
                                        required
                                        [tableInline]="false"
                                        placeholder="{{ 'general.chooseReseller' | translate}}"
                                        [(ngModel)]="selectedResellerId"
                                    >
                                    </fh-reseller-input>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group" style="margin-bottom: 6px">
                                    <fh-account-input
                                        name="account"
                                        #accountVar="ngModel"
                                        (onChanged)="accountChanged($event)"
                                        required
                                        [activeOnly]="true"
                                        [selectedResellerId]="selectedResellerId"
                                        [tableInline]="false"
                                        [showEmptyAccountsButton]="false"
                                        placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                        [(ngModel)]="selectedAccountId"
                                    >
                                    </fh-account-input>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group input-group" style="margin-bottom: 0px">
                                    <input
                                        placeholder="Daterangepicker"
                                        style="width: 100%"
                                        placeholder="Datepicker"
                                        type="text"
                                        [bsConfig]="dpConfig"
                                        class="form-control"
                                        bsDaterangepicker
                                        (onHidden)="dateChanged('hidden')"
                                        [(ngModel)]="daterangepickerModel"
                                    />
                                    <span class="input-group-calendar-datatables">
                                        <i class="fa fa-calendar-alt"></i>
                                    </span>
                                    <div style="clear: both"></div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group" style="margin-bottom: 6px">
                                    <fh-asset-group-input
                                        name="assetGroups"
                                        #assetGroupVar="ngModel"
                                        required
                                        [selectedAccountId]="selectedAccountId"
                                        [tableInline]="false"
                                        (onChanged)="dateChanged(true)"
                                        [multiple]="true"
                                        [autoSelect]="autoSelect"
                                        placeholder="{{ (selectedAccountId? 'general.chooseAssetGroups' : 'general.firstChooseAccount') | translate}}"
                                        [(ngModel)]="selectedAssetGroups"
                                    >
                                    </fh-asset-group-input>
                                </div>
                            </div>
                            <div style="clear: both"></div>
                        </div>
                    </div>
                    <div class="ibox-footer">
                        <button
                            *ngIf="!loading"
                            type="button"
                            name="search"
                            class="btn btn-primary"
                            (click)="dateChanged('hidden')"
                            [disabled]="selectedAccountId == null"
                        >
                            {{ 'general.fetchData' | translate}}
                        </button>

                        <button *ngIf="loading" type="button" name="cancel" class="btn btn-info" (click)="cancel()">
                            <i class="fas fa-fw fa-times"></i> {{ 'general.cancel' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="assignments.length == 0">
            <div class="col-lg-12">
                <div class="ibox">
                    <div class="ibox-content" style="position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <span *ngIf="!selectedAccountId">{{'general.pleaseSelectAccount' | translate }}</span>
                        <span *ngIf="selectedAccountId">{{'general.noDataFound' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="assignments.length !== 0">
            <div class="col-lg-12">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-route"></i> {{'general.trips' | translate }}</h5>
                        <span class="float-right">
                            <div class="filter-form d-none d-md-block">
                                <input
                                    type="text"
                                    class="form-control table-inline top-filter d-none d-md-block"
                                    (ngModelChange)="p.setCurrent(0)"
                                    [(ngModel)]="filter"
                                />
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: hidden">
                        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th>{{'general.asset' | translate }}</th>
                                    <th>{{'general.driver' | translate }}</th>
                                    <th>{{'general.start' | translate }}</th>
                                    <th>{{'general.duration' | translate }}</th>
                                    <th>{{'general.maxSpeed' | translate }}</th>
                                    <th style="text-align: right">{{'general.distance' | translate }}</th>
                                    <th>{{'general.start' | translate }}</th>
                                    <th>{{'general.end' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let assignment of assignments | orderBy : orderBy : orderByReversed | stringFilter: filter | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, id: 'first'}; "
                                >
                                    <td>
                                        <a
                                            class="secondary link_bolder"
                                            href="/#/DeviceDetails/Index/{{assignment?.deviceId}}"
                                        >
                                            {{ assignment.assetName ?? assignment.deviceId }}</a
                                        >
                                    </td>
                                    <td>{{assignment.driverName ?? '-'}}</td>
                                    <td>{{assignment.beginTS | amDateFormat: 'lll' }}</td>
                                    <td>{{assignment.duration | amDuration:'minutes' }}</td>
                                    <td>
                                        {{assignment.maxSpeed > -1 ? actualRound(assignment.maxSpeed,0) : '-'}}
                                        {{'abbreviation.kilometerPerHour' | translate}}
                                    </td>
                                    <td style="text-align: right">
                                        {{ actualRound(assignment.segmentDistanceInKilometers,2)?.toLocaleString() }}
                                        {{'abbreviation.kiloMeters' | translate}}
                                    </td>
                                    <td class="elipses_overflow">{{assignment.beginAddress ?? '-'}}</td>
                                    <td class="elipses_overflow">{{assignment.endAddress ?? '-'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event" id="first">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()"
                                            >{{ 'grid.previous' | translate }}</a
                                        >
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li
                                        class="paginate_button page-item"
                                        *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value"
                                    >
                                        <a
                                            class="page-link"
                                            (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value"
                                        >
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a
                                            class="page-link"
                                            (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value"
                                        >
                                            <span style="font-weight: bold">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear: both"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>
