import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormMode } from 'app/common/enums';
import { ServiceItem } from 'app/models/serviceItem.model';
import { AccountService } from 'app/services/account/account.service';
import { AssetTypeService } from 'app/services/asset/assetTypes.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { ServiceItemService } from 'app/services/serviceitem/serviceItem.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'fh-maintenance-service-item-details',
    templateUrl: 'serviceItemDetails.template.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class MaintenanceServiceItemDetailsComponent implements OnChanges, AfterViewInit {
    @Input() serviceItem: ServiceItem = new ServiceItem;
    @Input() formMode: any = FormMode.read;
    @Input() form;
    @Output() insert = new EventEmitter();
    @Output() update = new EventEmitter();

    loading: boolean;
    success: any;
    error: any;
    warning: any;

    formGroup: FormGroup;

    loadingAccounts = false;
    selectedResellerId;
    permissions: {};

    serviceItemId: number;

    assetTypes = [];
    assetTypeIds = [];

    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    constructor(
    private cd: ChangeDetectorRef, 
    private authenticationService: AuthenticationService, 
    private assetTypeService: AssetTypeService,
    private translateService : TranslateService,
    private serviceItemService: ServiceItemService,
    private router: Router,
    ) {
        this.permissions = this.authenticationService.permissions;
        this.dpConfig.containerClass = 'theme-default';
        this.dpConfig.dateInputFormat = 'll';
        this.dpConfig.isAnimated = true;
        this.dpConfig.withTimepicker = false;
        this.dpConfig.keepDatepickerOpened = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
      if (this.formMode == FormMode.read){
        if (changes.serviceItem?.currentValue?.accountId && changes.serviceItem?.currentValue?.assetTypeIds){
          this.getAssetTypes();
        }
      }
    }

    ngAfterViewInit(): void {
      this.formGroup = this.form.form;
    }

    accountChanged(accountId) {
      this.serviceItem.accountId = accountId;
  
      this.loading = true;
  
      if (this.serviceItem.accountId && this.serviceItem.accountId > 0) {
        this.getAssetTypes();
      } else {
        this.loading = false;
      }
    }

    resellerChanged(resellerId) {
        this.serviceItem.accountId = null;

        this.selectedResellerId = resellerId;
    }

    setFormMode(mode) {
      this.formMode = mode;
      if (this.formMode == FormMode.read){
        this.getAssetTypes()
      }
    }

    getAssetTypes() {
      this.assetTypeService.getAssetTypes(this.serviceItem.accountId).subscribe(result => {
        this.assetTypes = result;
        if (this.formMode === FormMode.read) {
          this.prefillAssetTypes();
        }
        this.loading = false;
        this.cd.markForCheck();
      });
    }

    prefillAssetTypes() {
      this.assetTypeService.getAssetTypes(this.serviceItem.accountId).subscribe(result => {;
          this.serviceItem.assetTypeIds.forEach(id => {
              id = Number(id);
              if (result.findIndex(item => item.id === id) !== -1) {
                  result.find(item => item.id === id).maintenanceTypeChecked = true;
              }
          });

          this.assetTypes = result;
          this.loading = false;
          this.cd.markForCheck();
      });
    }

    hasSelectedTypes(): boolean {
      return this.assetTypes.some(x => x.maintenanceTypeChecked === true);
    }

    hasSelectedInterval() {
      return (this.serviceItem.serviceInterval.dateBased || this.serviceItem.serviceInterval.dayBased || this.serviceItem.serviceInterval.engineHourBased || this.serviceItem.serviceInterval.distanceBased);
    }

    hasValidThreshold() {
      let dateThreshold = true;
      let distanceThreshold = true;
      let hourThreshold = true;
      let dayThreshold = true;

      if (this.serviceItem.serviceInterval.dateBased) {
        dateThreshold = this.serviceItem.serviceInterval.dateDueSoon < this.serviceItem.serviceInterval.dateThreshold;
      } 
      if (this.serviceItem.serviceInterval.dayBased) {
        dayThreshold = this.serviceItem.serviceInterval.daysDueSoon < this.serviceItem.serviceInterval.daysThreshold;
      }
      if (this.serviceItem.serviceInterval.engineHourBased) {
        hourThreshold = this.serviceItem.serviceInterval.hourDueSoon < this.serviceItem.serviceInterval.hourThreshold;
      }
      if (this.serviceItem.serviceInterval.distanceBased) {
        distanceThreshold = this.serviceItem.serviceInterval.kmDueSoon < this.serviceItem.serviceInterval.kmThreshold
      }
        
      return (dateThreshold && distanceThreshold && hourThreshold && dayThreshold);
    }

    clearThresholds(threshold: string) {
      switch(threshold) {
        case ('day') :
          this.serviceItem.serviceInterval.daysThreshold = undefined;
          this.serviceItem.serviceInterval.daysDueSoon = undefined;
          break;
        case ('hour') :
          this.serviceItem.serviceInterval.hourThreshold = undefined;
          this.serviceItem.serviceInterval.hourDueSoon = undefined;
          break;
        case ('date') :
          this.serviceItem.serviceInterval.dateThreshold = undefined;
          this.serviceItem.serviceInterval.dateDueSoon = undefined;
          break;
        case ('distance') :
          this.serviceItem.serviceInterval.kmThreshold = undefined;
          this.serviceItem.serviceInterval.kmDueSoon = undefined;
          break;
      }
    }

    onInsert() {
      this.serviceItem.assetTypeIds = this.parseAssetTypes();
      this.insert.emit(this.assetTypes);
    }

    onSave() {
      this.serviceItem.assetTypeIds = this.parseAssetTypes();
      this.update.emit();
    }

    onDelete() {
      this.loading = true;

      this.serviceItemService.deleteServiceItem(this.serviceItemId, this.serviceItem.accountId).subscribe(result => {
          this.error = null;
          this.success = {
              statusText: 'Success',
              success: 'Service item is successfully deleted.'
          };
          this.cd.markForCheck();

          setTimeout(() => {
              this.router.navigate(['/MaintenancesV2/ServiceItems/Overview']);
          }, 3000);
      }, error => {
          this.success = null;
          this.error = error;
          this.loading = false;
      });
    }

    parseAssetTypes() {
      const result: number[] = [];

      this.assetTypes.forEach(assetType => {
          if (assetType.maintenanceTypeChecked) {
              result.push(assetType.id);
          }
      });

      return result;
  }
}