import { ChangeDetectorRef, Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { GridBase360Directive } from "app/common/360Grid.base";
import { getGridButtons, getGridLanguages } from "app/common/gridhelper";
import { StorageHelper } from "app/common/storagehelper";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { ColorService } from "app/services/common/color.service";
import { MaintenanceV2Service } from "app/services/maintenancev2/maintenanceV2.service";
import Moment from "moment-timezone";

@Component({
  selector: "fh-maintenance-v2-due",
  templateUrl: "maintenanceDue.template.html",
})
export class MaintenanceV2DueViewComponent extends GridBase360Directive {
  token: string;
  success;
  error;
  warning;

  showActiveOnly = true;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  permissionName = "Maintenances_View";
  constructorName = "MaintenanceV2DueViewComponent";
  timezoneIana: string;

  datePickerModel: Date;

  constructor(
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    protected storageHelper: StorageHelper,
    private colorService: ColorService,
    private maintenanceService: MaintenanceV2Service
  ) {
    super(storageHelper);

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
    this.datePickerModel = Moment().subtract(0, "days").toDate();
  }

  ngOnInit() {
    this.translateService.get("general.date").subscribe((value) => {
      this.languageLoaded = true;
      this.initGrid();
    });
  }

  setBadgeColor(dueStatus: string) {
    if (dueStatus == "ok") {
      return "badge badge-success";
    } else if (dueStatus == "dueSoon") {
      return "badge badge-warning";
    } else {
      return "badge badge-danger";
    }
  }

  initGrid(): void {
    console.log("Init grid");

    $.fn["dataTable"].ext.search.pop();

    this.loading = true;

    this.columns = [
      {
        name: "id",
        data: "id",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        visible: false,
      },
      {
        name: "id_export",
        data: "id",
        className: "noVis",
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "assetName",
        data: "assetName",
        title: this.translateService.instant("general.assetName"),
      },
      {
        name: "serviceItemName",
        data: "serviceItemName",
        title: this.translateService.instant("general.maintenanceType"),
      },
      {
        name: "status",
        data: "status",
        title: this.translateService.instant("general.status"),
        iconName: "fas fa-fw fa-exclamation-triangle",
        type: "select",
        options: [
          { id: "Pastdue", value: this.translateService.instant("enums.pastDue.pastDue") },
          { id: "Imminent", value: this.translateService.instant("enums.pastDue.imminent") },
          { id: "Ok", value: this.translateService.instant("enums.pastDue.ok") },
        ],
        render: (data, type, row) => {
          let backgroundColor = this.setBadgeColor(data);

          return (
            '<span class="' +
            backgroundColor +
            '">' +
            this.translateService.instant("enums.pastDue." + data) +
            "</span>"
          );
        },
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "maintenanceDue_overview",
        this.translateService.instant("menu.deviceoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: (settings, data) => {
        this.saveState(this.constructorName, data);
      },
      stateLoadCallback: (_, callback) => {
        (async () => {
          try {
            const columnSettings = await this.loadState(this.constructorName);
            this.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            this.error = {};
            this.error.error = e;
            this.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      order: [[2, "asc"]],
      ajax: {
        beforeSend: () => {
          this.drawFilterRow();

          $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        },
        url: this.maintenanceService.getPagingDueUrl(this.datePickerModel.getTime()),
        type: "POST",
        headers: {
          Authorization: "Bearer " + this.token,
        },
      },
      initComplete: (settings, json) => {
        console.log("init complete");
        this.checkFilters();
        this.drawFilterRow();
        this.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
        exclude: ["id", "id_export"],
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
    };
  }
}
