import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { GridBase360Directive } from "app/common/360Grid.base";
import { getGridButtons, getGridLanguages } from "app/common/gridhelper";
import { StorageHelper } from "app/common/storagehelper";
import { Device } from "app/models/device.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { ColorService } from "app/services/common/color.service";
import { DeviceService } from "app/services/device/device.service";
import { MaintenanceV2Service } from "app/services/maintenancev2/maintenanceV2.service";
import Moment from "moment-timezone";

@Component({
  selector: "fh-device-maintenance-v2",
  templateUrl: "maintenanceV2.template.html",
})
export class DeviceMaintenanceV2ViewComponent extends GridBase360Directive {
  loading: boolean;
  languageLoaded: boolean;

  success;
  error;
  warning;

  device: Device = new Device();
  deviceId: string = "";
  assetId: number;

  permissions = {};
  permissionName = "FleetManagement_DeviceMaintenance";
  token: string;

  datePickerModel: Date;
  timezoneIana: string;
  constructorName = "DeviceMaintenanceV2ViewComponent";

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private deviceService: DeviceService,
    private translateService: TranslateService,
    private maintenanceService: MaintenanceV2Service,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
    this.datePickerModel = Moment().subtract(0, "days").toDate();
  }

  ngOnInit(): void {
    this.loading = true;

    this.route.params.subscribe((params) => {
      this.deviceId = params["id"];

      this.deviceService.getDeviceById(this.deviceId).subscribe((device) => {
        this.device = device;
        this.assetId = device.asset.id;

        this.translateService.get("general.date").subscribe((value) => {
          this.languageLoaded = true;
          this.initGrid();
        });
      });
    });
  }

  setBadgeColor(dueStatus: string) {
    if (dueStatus == "ok") {
      return "badge badge-success";
    } else if (dueStatus == "dueSoon") {
      return "badge badge-warning";
    } else {
      return "badge badge-danger";
    }
  }

  initGrid(): void {
    console.log("Init grid");

    $.fn["dataTable"].ext.search.pop();

    this.loading = true;

    this.columns = [
      {
        name: "id_export",
        data: "id",
        className: "noVis",
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "serviceItemId",
        data: "serviceItemId",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: (data, type, row) => {
          return (
            "<a class='btn btn-primary btn-grid' title='" +
            this.translateService.instant("general.details") +
            "' href='/#/MaintenancesV2/ServiceItems/Details/Index/" +
            data +
            '\'><span class="d-none d-md-inline-flex" style="padding-left: 7px">' +
            this.translateService.instant("general.details") +
            " </span><i class='fas fa-fw fa-angle-right'></i></a>"
          );
        },
      },
      {
        name: "serviceItemName",
        data: "serviceItemName",
        title: this.translateService.instant("general.maintenanceType"),
      },
      {
        name: "currentValues",
        title: this.translateService.instant("general.currentValue"),
        render: (data, type, row) => {
          let result = "";
          if (row?.serviceInterval?.dateBased) {
            const date = Moment.utc(row.dueDate)["tz"](this.timezoneIana);
            result += date.format("ll");
          }
          if (row?.serviceInterval?.dayBased) {
            if (result !== "") {
              result += "</br>";
            }
            result += row.daysCountdown + " days";
          }
          if (row?.serviceInterval?.distanceBased) {
            if (result !== "") {
              result += "</br>";
            }
            result += row.distanceCountdown + " km";
          }
          if (row?.serviceInterval?.hourThreshold) {
            if (result !== "") {
              result += "</br>";
            }
            result += row.engineHourCountdown + " hour";
          }
          return result == "" ? "-" : result;
        },
      },
      {
        name: "dueSoonThreshold",
        title: this.translateService.instant("general.dueSoonThreshold"),
        render: (data, type, row) => {
          let result = "";
          if (row?.serviceInterval?.dateBased) {
            const date = Moment.utc(row.serviceInterval.dateDueSoon)["tz"](this.timezoneIana);
            result += date.format("ll");
          }
          if (row?.serviceInterval?.dayBased) {
            if (result !== "") {
              result += " or ";
            }
            result += row.serviceInterval.daysDueSoon + " days";
          }
          if (row?.serviceInterval?.distanceBased) {
            if (result !== "") {
              result += " or ";
            }
            result += row.serviceInterval.kmDueSoon + " km";
          }
          if (row?.serviceInterval?.hourThreshold) {
            if (result !== "") {
              result += " or ";
            }
            result += row.serviceInterval.hourDueSoon + " hour";
          }
          return result == "" ? "-" : result;
        },
      },
      {
        name: "maintenanceThreshold",
        defaultContent: "-",
        title: this.translateService.instant("general.maintenanceThreshold"),
        render: (data, type, row) => {
          let result = "";
          if (row?.serviceInterval?.dateBased) {
            const date = Moment.utc(row.serviceInterval.dateThreshold)["tz"](this.timezoneIana);
            result += date.format("ll");
          }
          if (row?.serviceInterval?.dayBased) {
            if (result !== "") {
              result += " or ";
            }
            result += row.serviceInterval.daysThreshold + " days";
          }
          if (row?.serviceInterval?.distanceBased) {
            if (result !== "") {
              result += " or ";
            }
            result += row.serviceInterval.kmThreshold + " km";
          }
          if (row?.serviceInterval?.hourThreshold) {
            if (result !== "") {
              result += " or ";
            }
            result += row.serviceInterval.hourThreshold + " hour";
          }
          return result == "" ? "-" : result;
        },
      },
      {
        name: "lastMaintenance",
        data: "lastMaintenance",
        defaultContent: "-",
        title: this.translateService.instant("general.lastMaintenanceEvent"),
        render: (data, type, row) => {
          let result = "-";
          if (data) {
            const date = Moment.utc(row.lastMaintenance)["tz"](this.timezoneIana);
            result = date.format("lll");
          }
          return result;
        },
      },
      {
        name: "status",
        data: "status",
        title: this.translateService.instant("general.status"),
        iconName: "fas fa-fw fa-exclamation-triangle",
        type: "select",
        options: [
          { id: "Pastdue", value: this.translateService.instant("enums.pastDue.pastDue") },
          { id: "Imminent", value: this.translateService.instant("enums.pastDue.imminent") },
          { id: "Ok", value: this.translateService.instant("enums.pastDue.ok") },
        ],
        render: (data, type, row) => {
          let backgroundColor = this.setBadgeColor(data);

          return (
            '<span class="' +
            backgroundColor +
            '">' +
            this.translateService.instant("enums.pastDue." + data) +
            "</span>"
          );
        },
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "maintenance_overview",
        this.translateService.instant("menu.messagesoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: false,
      processing: false,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      order: [[2, "asc"]],
      stateSave: false,
      ajax: (dataTablesParameters: any, callback) => {
        $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        this.loading = true;

        this.maintenanceService.getMaintenancesByAssetId(this.assetId).subscribe({
          next: (resp) => {
            callback({
              recordsTotal: resp.length,
              recordsFiltered: resp.length,
              data: resp,
            });
          },
          error: (error) => {
            this.success = null;
            this.error = error;
            this.loading = false;
          },
        });
      },
      initComplete: (settings, json) => {
        this.loading = false;

        console.log("init complete");
        this.checkFilters();
        this.drawFilterRow();
        this.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, 200, -1],
        [10, 17, 25, 50, 200, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
      rowCallback: (row, data) => {},
    };
  }
}
