import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { WorkOrder } from "app/models/workOrder.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { WorkOrderService } from "app/services/workorder/workOrder.service";
import { MaintenanceWorkOrderDetailsComponent } from "app/views/shared/usercontrols/workOrderDetails.component";

@Component({
  selector: "fh-fh-maintenance-work-order-details",
  templateUrl: "workOrderDetails.template.html",
})
export class MaintenanceWorkOrderDetailsViewComponent implements OnInit {
  @ViewChild(MaintenanceWorkOrderDetailsComponent, { static: true })
  workOrderDetails: MaintenanceWorkOrderDetailsComponent;
  formMode = FormMode.read;

  workOrderId: number;
  workOrder: WorkOrder;

  permissions: {};
  permissionName = "Maintenances_View";

  constructor(
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private workOrderService: WorkOrderService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.permissions = this.authenticationService.permissions;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.workOrderId = params["id"];
      this.workOrderDetails.workOrderId = this.workOrderId;

      this.workOrderDetails.loading = true;

      this.workOrderService.getWorkOrderById(this.workOrderId).subscribe({
        next: (result) => {
          this.workOrder = result;

          //Setting that an item is retrieved from database
          this.workOrder.workOrderItems.forEach((item) => {
            item.exists = true;
          });
          this.workOrderDetails.loading = false;
          this.cd.markForCheck();
        },
        error: (error) => {
          this.workOrderDetails.loading = false;
          this.workOrderDetails.success = null;
          this.workOrderDetails.error = error;
          this.cd.markForCheck();
        },
      });
    });
  }

  OnSave(workOrder) {
    this.workOrderDetails.loading = true;

    this.workOrderService.updateWorkOrder(this.workOrderId, workOrder).subscribe({
      next: (result) => {
        this.workOrderDetails.error = null;
        this.workOrderDetails.success = {
          statusText: "Success",
          success: "Work order successfully updated",
        };

        this.workOrderDetails.loading = false;
        this.cd.markForCheck();

        this.workOrderDetails.setFormMode(FormMode.read);

        setTimeout(() => {
          location.reload();
        }, 2000);
      },
      error: (error) => {
        this.workOrderDetails.loading = false;
        this.workOrderDetails.success = null;
        this.workOrderDetails.error = error;
        this.cd.markForCheck();
      },
    });
  }
}
