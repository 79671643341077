import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ControlContainer, FormGroup, NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { FormMode } from "app/common/enums";
import { ServiceItem } from "app/models/serviceItem.model";
import { WorkOrder, WorkOrderItem } from "app/models/workOrder.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { ServiceItemService } from "app/services/serviceitem/serviceItem.service";
import { WorkOrderService } from "app/services/workorder/workOrder.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";

@Component({
  selector: "fh-maintenance-work-order-details",
  templateUrl: "workOrderDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class MaintenanceWorkOrderDetailsComponent implements OnInit, AfterViewInit {
  @Input() form;
  @Input() formMode = FormMode.read;
  @Input() workOrder: WorkOrder = new WorkOrder();
  @Output() insert = new EventEmitter();
  @Output() update = new EventEmitter();

  success;
  error;
  loading = false;

  token: string;

  workOrderId: number;

  formGroup: FormGroup;
  permissionName = "Maintenances_View";

  permissions: {};

  selectedResellerId;
  serviceItems: ServiceItem[] = [];
  asset;

  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  constructor(
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private serviceItemService: ServiceItemService,
    private workOrderService: WorkOrderService,
    private router: Router
  ) {
    // Datepicker
    this.dpConfig.containerClass = "theme-default"; // or whatever color
    this.dpConfig.dateInputFormat = "lll";
    this.dpConfig.isAnimated = true;
    this.dpConfig.withTimepicker = true;
    this.dpConfig.keepDatepickerOpened = true;

    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();
  }

  ngOnInit(): void {
    this.addWorkOrderItem();
    this.getServiceItems();
  }

  ngAfterViewInit(): void {
    this.formGroup = this.form.form;
  }

  setFormMode(mode) {
    this.formMode = mode;
  }

  resellerChanged(resellerId) {
    this.workOrder.accountId = null;

    this.selectedResellerId = resellerId;

    this.accountChanged(null);

    setTimeout(() => {
      this.cd.markForCheck();
    }, 100);
  }

  accountChanged(accountId) {
    this.workOrder.accountId = accountId;
  }

  handleAssetChanged(asset) {
    this.workOrder.assetId = asset ? asset.id : undefined;
    this.asset = asset;
  }

  getServiceItems(): void {
    this.serviceItemService.getServiceItems().subscribe((result) => {
      this.serviceItems = result;
    });
  }

  addWorkOrderItem() {
    const newItem = new WorkOrderItem();
    this.workOrder?.workOrderItems.push(newItem);
  }

  deleteWorkOrderItem(item: WorkOrderItem) {
    const index: number = this.workOrder.workOrderItems.indexOf(item);
    if (index !== -1) {
      this.workOrder.workOrderItems.splice(index, 1);
    }
  }

  onInsert() {
    this.insert.emit(this.workOrder);
  }

  onSave() {
    this.update.emit(this.workOrder);
  }

  onDelete() {
    this.loading = true;

    this.workOrderService.deleteWorkOrder(this.workOrderId, this.workOrder.assetId).subscribe(
      (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "work order is successfully deleted.",
        };
        this.cd.markForCheck();

        setTimeout(() => {
          this.router.navigate(["/MaintenancesV2/WorkOrders/Overview"]);
        }, 3000);
      },
      (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
      }
    );
  }
}
