<div class="row">
    <div class="col-lg-12">
        <fh-error [success]="success" [error]="error"></fh-error>
    </div>
</div>

<div class="row">
    <div class="col-md-6">
        <div class="ibox" style="position: relative">
            <div class="ibox-title">
                <h5><i class="fas fa-fw fa-garage-car"></i> {{ "general.workOrder" | translate }}</h5>
            </div>
            <div class="ibox-content slick" style="position: relative">
                <ngx-loading [show]="loading"></ngx-loading>
                <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                    <tbody *ngIf="!workOrder">
                        <tr>
                            <td colspan="2">
                                {{ "general.noMaintenance" | translate }}
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="workOrder">
                        <ng-container *ngIf="formMode != 2">
                            <tr>
                                <td class="row_opening">
                                    {{ "general.resellerDescription" | translate }}
                                </td>
                                <td class="padding-readonly-correct row_content">
                                    <a
                                        class="secondary link_bolder"
                                        href="/#/ResellerDetails/Index/{{ workOrder?.resellerId }}"
                                        >{{ workOrder?.resellerDescription }}</a
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ "general.companyName" | translate }}
                                </td>
                                <td class="row_content padding-readonly-correct">
                                    <a
                                        class="secondary link_bolder"
                                        href="/#/AccountDetails/Index/{{ workOrder?.accountId }}"
                                        >{{ workOrder?.companyName }}</a
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ "general.asset" | translate }}
                                </td>
                                <td class="row_content padding-readonly-correct">
                                    <a
                                        class="secondary link_bolder"
                                        href="/#/DeviceDetails/Index/{{ workOrder?.deviceId }}"
                                        >{{ workOrder?.assetName }}</a
                                    >
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="formMode == 2">
                            <tr>
                                <td class="row_opening">
                                    {{ "general.reseller" | translate }}
                                    <span class="error" *ngIf="resellerVar.invalid">*</span>
                                </td>
                                <td class="row_content" style="position: relative; overflow: visible">
                                    <fh-reseller-input
                                        name="reseller"
                                        #resellerVar="ngModel"
                                        (onChanged)="resellerChanged($event)"
                                        required
                                        placeholder="{{ 'general.chooseReseller' | translate }}"
                                        [(ngModel)]="selectedResellerId"
                                    >
                                    </fh-reseller-input>
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ "general.account" | translate }}
                                    <span class="error" *ngIf="accountVar.invalid">*</span>
                                </td>
                                <td class="row_content" style="position: relative; overflow: visible">
                                    <fh-account-input
                                        name="account"
                                        #accountVar="ngModel"
                                        (onChanged)="accountChanged($event)"
                                        required
                                        [tableInline]="true"
                                        [selectedResellerId]="selectedResellerId"
                                        placeholder="{{
                                            (selectedResellerId
                                                ? 'general.chooseAccount'
                                                : 'general.firstChooseReseller'
                                            ) | translate
                                        }}"
                                        [(ngModel)]="workOrder.accountId"
                                    >
                                    </fh-account-input>
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ "general.asset" | translate }}
                                    <span class="error" *ngIf="!(workOrder.assetId > 0)">*</span>
                                </td>
                                <td class="row_content" style="position: relative; overflow: visible">
                                    <fh-asset-chooser
                                        name="asset"
                                        [assetId]="workOrder.assetId"
                                        [accountId]="workOrder.accountId"
                                        [formMode]="formMode"
                                        [activeOnly]="true"
                                        (onAssetChanged)="handleAssetChanged($event)"
                                    >
                                    </fh-asset-chooser>
                                </td>
                            </tr>
                        </ng-container>
                        <tr>
                            <td class="row_opening">
                                {{ "general.occurrenceDate" | translate }}
                                <span class="error" *ngIf="serviceDate.invalid && formMode != 1">*</span>
                            </td>
                            <td class="row_content">
                                <input
                                    type="text"
                                    [disabled]="formMode == 1"
                                    name="serviceDate"
                                    #serviceDate="ngModel"
                                    class="form-control table-inline"
                                    [ngModel]="workOrder.serviceDate | amDateFormat : 'lll'"
                                    (ngModelChange)="workOrder.serviceDate = $event"
                                    placeholder="{{ 'placeholder.occurrenceDate' | translate }}"
                                    [bsConfig]="dpConfig"
                                    required
                                    bsDatepicker
                                    autocomplete="off"
                                    #serviceDateDp="bsDatepicker"
                                />
                                <span class="input-group-calendar"
                                    ><i class="fa fa-calendar-alt" (click)="serviceDateDp.toggle()"></i
                                ></span>
                            </td>
                        </tr>

                        <tr>
                            <td class="row_opening">
                                {{ "general.engineHours" | translate }}
                                <span class="error" *ngIf="engineHours.invalid && formMode != 1">*</span>
                            </td>
                            <td class="row_content">
                                <input
                                    type="number"
                                    [readonly]="formMode == 1"
                                    required
                                    name="engineHours"
                                    class="form-control table-inline"
                                    [(ngModel)]="workOrder.engineHours"
                                    #engineHours="ngModel"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td class="row_opening">
                                {{ "general.odo" | translate }}
                                <span class="error" *ngIf="odoMeter.invalid && formMode != 1">*</span>
                            </td>
                            <td class="row_content">
                                <input
                                    type="number"
                                    [readonly]="formMode == 1"
                                    required
                                    name="odoMeter"
                                    class="form-control table-inline"
                                    [(ngModel)]="workOrder.odometer"
                                    #odoMeter="ngModel"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td class="row_opening">
                                {{ "general.completed" | translate }}
                                <span class="error" *ngIf="completed.invalid && formMode != 1">*</span>
                            </td>
                            <td class="row_content">
                                <div class="fa_checkbox">
                                    <input
                                        id="completed"
                                        name="completed"
                                        type="checkbox"
                                        [disabled]="formMode == 1"
                                        [(ngModel)]="workOrder.completed"
                                        #completed="ngModel"
                                    />
                                    <label for="completed"> </label>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="formMode == 1">
                            <td class="row_opening">
                                {{ "general.cost" | translate }}
                            </td>
                            <td class="row_content">
                                <input
                                    type="number"
                                    [readonly]="true"
                                    name="totalCost"
                                    class="form-control table-inline"
                                    [(ngModel)]="workOrder.totalCost"
                                    #itemPrice="ngModel"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <fh-save-footer
                [valid]="!form.invalid && workOrder?.workOrderItems.length > 0"
                (onInsert)="onInsert()"
                [formMode]="formMode"
                [allowEdit]="permissions['Maintenances_Edit']"
                [allowDelete]="permissions['Maintenances_Delete']"
                (setFormMode)="setFormMode($event)"
                (onSave)="onSave()"
                (onDelete)="onDelete()"
            >
                <button
                    type="button"
                    class="btn btn-primary float-right"
                    [disabled]="formMode == 1"
                    (click)="addWorkOrderItem()"
                >
                    <i class="fas fa-fw fa-plus"></i>
                </button>
            </fh-save-footer>
        </div>
    </div>

    <div class="col-md-6">
        <div class="ibox" style="position: relative" *ngFor="let item of workOrder?.workOrderItems; let i = index">
            <div class="ibox-title">
                <h5><i class="fas fa-fw fa-wrench-simple"></i> {{ "general.workOrderItem" | translate }}</h5>
                <button
                    class="btn btn-danger float-right"
                    (click)="deleteWorkOrderItem(item)"
                    [disabled]="formMode == 1"
                >
                    <i class="fa fa-fw fa-trash-alt"></i>
                </button>
            </div>
            <div class="ibox-content slick" style="position: relative">
                <ngx-loading [show]="loading"></ngx-loading>
                <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                    <tbody>
                        <ng-container *ngIf="formMode != 1">
                            <tr *ngIf="!item.exists">
                                <td class="row_opening">{{ "general.serviceItem" | translate }}</td>
                                <td class="row_content" style="overflow: visible">
                                    <ng-select
                                        [searchable]="false"
                                        [clearable]="true"
                                        [multiple]="false"
                                        [(ngModel)]="item.serviceItemId"
                                        placeholder="{{ 'general.chooseServiceItem' | translate }}"
                                        [name]="'serviceItem_' + i"
                                        #serviceItem="ngModel"
                                        class="form-control table-inline"
                                    >
                                        <ng-option
                                            *ngFor="let serviceItem of serviceItems | orderBy : 'name' : false"
                                            [value]="serviceItem.id"
                                        >
                                            <i class="fas fa-fw fa-screwdriver-wrench"></i> {{ serviceItem.name }}
                                        </ng-option>
                                    </ng-select>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="formMode != 2">
                            <tr *ngIf="item.serviceItemId && item.exists">
                                <td class="row_opening">{{ "general.serviceItem" | translate }}</td>
                                <td *ngIf="item.serviceItemName" class="row_content padding-readonly-correct">
                                    <a
                                        class="secondary link_bolder"
                                        href="/#/MaintenancesV2/ServiceItems/Details/Index/{{ item?.serviceItemId }}"
                                        >{{ item?.serviceItemName }}</a
                                    >
                                </td>
                                <td *ngIf="!item.serviceItemName" class="row_content">
                                    <span>{{ "general.serviceItemDeleted" | translate }}</span>
                                </td>
                            </tr>
                        </ng-container>
                        <tr>
                            <td class="row_opening">
                                {{ "general.description" | translate }}
                                <span class="error" *ngIf="description.invalid && formMode != 1">*</span>
                            </td>
                            <td>
                                <textarea
                                    [readonly]="formMode == 1"
                                    [name]="'description_' + i"
                                    #description="ngModel"
                                    required
                                    class="form-control noresize"
                                    style="height: 100px; width: 100%; resize: none"
                                    [(ngModel)]="item.description"
                                >
                                </textarea>
                            </td>
                        </tr>
                        <tr>
                            <td class="row_opening">
                                {{ "general.quantity" | translate }}
                                <span class="error" *ngIf="quantity.invalid && formMode != 1">*</span>
                            </td>
                            <td class="row_content">
                                <input
                                    type="number"
                                    [readonly]="formMode == 1"
                                    [name]="'quantity_' + i"
                                    class="form-control table-inline"
                                    [(ngModel)]="item.quantity"
                                    required
                                    #quantity="ngModel"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td class="row_opening">
                                {{ "general.itemPrice" | translate }}
                            </td>
                            <td class="row_content">
                                <input
                                    type="number"
                                    [readonly]="formMode == 1"
                                    [name]="'itemPrice_' + i"
                                    class="form-control table-inline"
                                    [(ngModel)]="item.itemPrice"
                                    #itemPrice="ngModel"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td class="row_opening">
                                {{ "general.laborCost" | translate }}
                            </td>
                            <td class="row_content">
                                <input
                                    type="number"
                                    [readonly]="formMode == 1"
                                    [name]="'laborCost_' + i"
                                    class="form-control table-inline"
                                    [(ngModel)]="item.laborCost"
                                    #laborCost="ngModel"
                                />
                            </td>
                        </tr>
                        <tr *ngIf="formMode == 1">
                            <td class="row_opening">
                                {{ "general.cost" | translate }}
                            </td>
                            <td class="row_content">
                                <input
                                    type="number"
                                    [readonly]="true"
                                    [name]="'totalCost_' + i"
                                    class="form-control table-inline"
                                    [(ngModel)]="item.totalCost"
                                    #itemPrice="ngModel"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
