export class ServiceItem {
    id: number;
    accountId: number;
    name: string;
    description: string;
    timestamp: Date;
    serviceInterval: ServiceInterval;
    assetTypeIds: number[];
    resellerId: number;
    resellerDescription: string;
    companyName: string;
    assetCount: number;
    occurrenceCount: number;
}

export class ServiceInterval {
    distanceBased: boolean = false;
    engineHourBased: boolean = false;
    dateBased: boolean = false; 
    dayBased: boolean = false;
    kmThreshold: number;
    hourThreshold: number;
    dateThreshold: Date;
    daysThreshold: number;
    kmDueSoon: number;
    hourDueSoon: number;
    dateDueSoon: Date;
    daysDueSoon: number;
}