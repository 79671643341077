import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { ServiceInterval, ServiceItem } from "app/models/serviceItem.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { ServiceItemService } from "app/services/serviceitem/serviceItem.service";
import { MaintenanceServiceItemDetailsComponent } from "app/views/shared/usercontrols/serviceItemDetails.component";
import { Router } from "@angular/router";

@Component({
    selector: "fh-maintenance-service-item-add",
    templateUrl: "addServiceItem.template.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceServiceItemAddViewComponent {
    @ViewChild(MaintenanceServiceItemDetailsComponent, { static: true }) serviceItemDetails: MaintenanceServiceItemDetailsComponent;

    serviceItem = new ServiceItem;

    formMode: FormMode = FormMode.add;
    permissions: {};

    permissionName = "MaintenanceTypes_Add";

    constructor(
        private cd: ChangeDetectorRef, 
        private authenticationService: AuthenticationService, 
        private serviceItemService: ServiceItemService,
        private router: Router
    ) {
        this.serviceItem.serviceInterval = new ServiceInterval;
        this.permissions = this.authenticationService.permissions;
    }

    onInsert() {
        this.serviceItemDetails.loading = true;
    
        this.serviceItemService.createServiceItem(this.serviceItem).subscribe(result => {
            this.serviceItemDetails.success = {
            statusText: "Success",
            success: "Service item is successfully added"
          };

          this.serviceItemDetails.loading = false;
          this.cd.markForCheck();
    
          setTimeout(() => {
            this.router.navigate(["/MaintenancesV2/ServiceItems/Overview/"]);
          }, 3000);
    
        }, error => {
            this.serviceItemDetails.error = error
            this.serviceItemDetails.loading = false;
            this.cd.markForCheck();
        });
    }
}