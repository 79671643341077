import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { GridBase360Directive } from "app/common/360Grid.base";
import { roundAsNumber } from "app/common/globals";
import { createdCellCheckbox, getGridButtons, getGridLanguages } from "app/common/gridhelper";
import { StorageHelper } from "app/common/storagehelper";
import { Device } from "app/models/device.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { ColorService } from "app/services/common/color.service";
import { DeviceService } from "app/services/device/device.service";
import { MaintenanceV2Service } from "app/services/maintenancev2/maintenanceV2.service";
import Moment from "moment-timezone";

@Component({
  selector: "fh-device-maintenancev2-history",
  templateUrl: "maintenanceV2History.template.html",
})
export class DeviceMaintenanceV2HistoryViewComponent extends GridBase360Directive implements OnInit {
  loading: boolean;
  languageLoaded: boolean;

  success;
  error;
  warning;

  device: Device = new Device();
  deviceId: string = "";
  assetId: number;

  permissions = {};
  permissionName = "FleetManagement_DeviceMaintenance";
  token: string;

  datePickerModel: Date;
  timezoneIana: string;
  constructorName = "DeviceMaintenanceV2HistoryViewComponent";

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private deviceService: DeviceService,
    private translateService: TranslateService,
    private maintenanceService: MaintenanceV2Service,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
    this.datePickerModel = Moment().subtract(0, "days").toDate();
  }

  ngOnInit(): void {
    this.loading = true;

    this.route.params.subscribe((params) => {
      this.deviceId = params["id"];

      this.deviceService.getDeviceById(this.deviceId).subscribe((device) => {
        this.device = device;
        this.assetId = device.asset.id;

        this.translateService.get("general.date").subscribe((value) => {
          this.languageLoaded = true;
          this.initGrid();
        });
      });
    });
  }

  initGrid(): void {
    console.log("Init grid");

    $.fn["dataTable"].ext.search.pop();

    this.loading = true;

    this.columns = [
      {
        name: "workOrderId",
        data: "workOrderId",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: (data, type, row) => {
          return (
            "<a class='btn btn-primary btn-grid' title='" +
            this.translateService.instant("general.details") +
            "' href='/#/MaintenancesV2/WorkOrders/Details/Index/" +
            data +
            '\'><span class="d-none d-md-inline-flex" style="padding-left: 7px">' +
            this.translateService.instant("general.details") +
            " </span><i class='fas fa-fw fa-angle-right'></i></a>"
          );
        },
      },
      {
        name: "id_export",
        data: "id",
        className: "noVis",
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "serviceItemName",
        data: "serviceItemName",
        title: this.translateService.instant("general.maintenanceType"),
      },
      {
        name: "serviceDate",
        data: "serviceDate",
        defaultContent: "-",
        title: this.translateService.instant("general.date"),
        render: (data, type, row) => {
          if (data) {
            if (type && type === "display") {
              const date = Moment.utc(data)["tz"](this.timezoneIana);
              return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "";
            } else {
              return data;
            }
          }
          return "-";
        },
      },
      {
        name: "cost",
        data: "totalCost",
        defaultContent: "-",
        title: this.translateService.instant("general.cost"),
        render: function (data, type, row) {
          if (type && type === "display") {
            if (data) {
              const value = roundAsNumber(data as number, 2).toLocaleString();
              return value;
            }
            return "-";
          }
          return data;
        },
      },
      {
        name: "recurrence",
        defaultContent: "-",
        title: this.translateService.instant("general.recurrence"),
        render: (data, type, row) => {
          let result = "";
          if (row?.serviceInterval?.dateBased) {
            const date = Moment.utc(row.serviceInterval.dateThreshold)["tz"](this.timezoneIana);
            result += date.format("ll");
          }
          if (row?.serviceInterval?.dayBased) {
            if (result !== "") {
              result += " or ";
            }
            result += row.serviceInterval.daysThreshold + " days";
          }
          if (row?.serviceInterval?.distanceBased) {
            if (result !== "") {
              result += " or ";
            }
            result += row.serviceInterval.kmThreshold + " km";
          }
          if (row?.serviceInterval?.hourThreshold) {
            if (result !== "") {
              result += " or ";
            }
            result += row.serviceInterval.hourThreshold + " hour";
          }
          return result == "" ? "-" : result;
        },
      },
      {
        name: "planned",
        data: "planned",
        title: this.translateService.instant("general.planned"),
        visible: true,
        render: (data, type, row) => {
          return (
            '<span class="' +
            (data ? "badge badge-primary" : "badge badge-secondary") +
            '">' +
            (data
              ? this.translateService.instant("general.planned")
              : this.translateService.instant("general.incidental")) +
            "</span>"
          );
        },
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "maintenancehistory_overview",
        this.translateService.instant("menu.deviceoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: (settings, data) => {
        this.saveState(this.constructorName, data);
      },
      stateLoadCallback: function (_, callback) {
        (async () => {
          try {
            const columnSettings = await this.loadState(this.constructorName);
            this.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            this.error = {};
            this.error.error = e;
            this.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      order: [[2, "asc"]],
      ajax: {
        beforeSend: () => {
          this.drawFilterRow();

          $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        },
        url: this.maintenanceService.getPagingHistoryUrl(this.assetId, this.datePickerModel.getTime()),
        type: "POST",
        headers: {
          Authorization: "Bearer " + this.token,
        },
      },
      initComplete: (settings, json) => {
        console.log("init complete");
        this.checkFilters();
        this.drawFilterRow();
        this.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
        exclude: ["id", "id_export"],
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
    };
  }
}
