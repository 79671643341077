import { Routes } from "@angular/router";
import { DeviceSettingsViewComponent } from "app/views/devices/deviceSettings.component";
import { DevicesMapViewComponent } from "app/views/devices/map.component";
import { AuthGuard } from "./auth-guard";

import { AboutViewComponent } from "./views/appviews/about.component";
import { DashboardComponent } from "./views/dashboards/dashboard.component";
import { Error404Component } from "./views/appviews/error404.component";
import { ForgotComponent } from "./views/appviews/forgotPassword.component";
import { HelpViewComponent } from "./views/appviews/help.component";
// Components
import { LoginComponent } from "./views/appviews/login.component";
import { RegisterComponent } from "./views/appviews/register.component";
import { SearchViewComponent } from "./views/appviews/search.component";
import { TagListViewComponent } from "./views/appviews/taglist.component";
import { TagsViewComponent } from "./views/appviews/tags.component";
import { UnsubscribeComponent } from "./views/appviews/unsubscribe.component";
// Accounts
import { DeviceAddViewComponent } from "./views/devices/add.component";
import { DeviceDetailsViewComponent } from "./views/devices/deviceDetails.component";
import { DeviceLogViewComponent } from "./views/devices/deviceLog.component";
import { DevicesViewComponent } from "./views/devices/devices.component";
import { DeviceSensorSettingsViewComponent } from "./views/devices/sensorSettings.component";
import { DeviceTripsViewComponent } from "./views/devices/trips.component";
import { DeviceTypeDetailsViewComponent } from "./views/devices/devicetypes/deviceTypeDetails.component";
// Device types
import { DevicesTypeViewComponent } from "./views/devices/devicetypes/deviceTypes.component";
import { DriversAddViewComponent } from "./views/drivers/add.component";
import { DriverDetailsViewComponent } from "./views/drivers/driverDetails.component";
import { DriverLogViewComponent } from "./views/drivers/driverLog.component";
import { DriversViewComponent } from "./views/drivers/drivers.component";
// Issues

import { ReportingCreateViewComponent } from "./views/reporting/reportingCreate.component";
import { SettingsGeneralComponent } from "./views/settings/general.component";
import { LanguageSettingsComponent } from "./views/settings/language.component";

// Settings
import { SettingsComponent } from "./views/settings/settings.component";
import { ThemeSettingsComponent } from "./views/settings/theme.component";
import { BasicLayoutComponent } from "./views/shared/layouts/basicLayout.component";
import { BlankLayoutComponent } from "./views/shared/layouts/blankLayout.component";
import { ShareLayoutComponent } from "./views/shared/layouts/shareLayout.component";
import { UserDetailsViewComponent } from "./views/users/userDetails.component";
import { UserLogViewComponent } from "./views/users/userLog.component";
// Users
import { UsersViewComponent } from "./views/users/users.component";
import { UserAddViewComponent } from "./views/users/userAdd.component";
import { DeviceMessageViewComponent } from "./views/devices/messages.component";

import { GeofencesViewComponent } from "./views/geofences/geofences.component";
import { GeofencesAddViewComponent } from "./views/geofences/add.component";
import { GeofenceDetailsViewComponent } from "./views/geofences/geofenceDetails.component";
import { GeofenceLogViewComponent } from "./views/geofences/geofenceLog.component";

import { AccountsAddViewComponent } from "./views/accounts/add.component";
import { AccountsViewComponent } from "./views/accounts/accounts.component";
import { AccountDetailsViewComponent } from "./views/accounts/accountDetails.component";
import { AccountLogViewComponent } from "./views/accounts/accountLog.component";

import { ResellersAddViewComponent } from "./views/resellers/add.component";
import { ResellersViewComponent } from "./views/resellers/resellers.component";
import { ResellerDetailsViewComponent } from "./views/resellers/resellerDetails.component";

import { DriverGroupViewComponent } from "./views/drivers/groups.component";
import { DriverGroupAddViewComponent } from "./views/drivers/groupAdd.component";
import { DriverGroupDetailsViewComponent } from "./views/drivers/groupDetails.component";
import { GeofenceGroupDetailsViewComponent } from "./views/geofences/groupDetails.component";
import { GeofenceGroupViewComponent } from "./views/geofences/groups.component";
import { GeofenceGroupAddViewComponent } from "./views/geofences/groupAdd.component";
import { AssetGroupViewComponent } from "./views/assets/groups.component";
import { AssetGroupAddViewComponent } from "./views/assets/groupAdd.component";
import { AssetGroupDetailsViewComponent } from "./views/assets/groupDetails.component";
import { PlaceholderViewComponent } from "./views/appviews/placeHolder.component";
import { BatchViewComponent } from "./views/system/batch/batch.component";
import { BatchDetailsViewComponent } from "./views/system/batch/batchDetails.component";
import { DeviceWaslViewComponent } from "./views/devices/wasl.component";
import { DriverWaslViewComponent } from "./views/drivers/wasl.component";
import { AccountWaslViewComponent } from "./views/accounts/wasl.component";
import { DeviceEpisodesViewComponent } from "./views/devices/episodes.component";
import { DeviceNotificationsViewComponent } from "./views/devices/notifications.component";
import { DeviceImmobilizationViewComponent } from "./views/devices/immobilization.component";
import { DeviceChangesViewComponent } from "./views/devices/changes.component";
import { DeviceRawDataViewComponent } from "./views/devices/rawData.component";
import { UserNotificationsViewComponent } from "./views/users/notifications.component";
import { TriggersViewComponent } from "./views/notifications/triggers/triggers.component";
import { TriggerDetailsViewComponent } from "./views/notifications/triggers/triggerDetails.component";
import { ArchiveRetentionRuleViewComponent } from "./views/settings/archiveretentionrules/archiveretentionrule.component";
// import { ArchiveRetentionRuleDetailsViewComponent } from './views/archiveretentionrules/archiveretentionrulesDetails.component';
import { FuelViewComponent } from "./views/fuel/fuel.component";
import { FuelDetailsViewComponent } from "./views/fuel/fuelDetails.component";
import { MaintenanceDetailsViewComponent } from "./views/maintenance/maintenanceDetails.component";
import { MaintenanceViewComponent } from "./views/maintenance/maintenance.component";
import { MaintenanceAddViewComponent } from "./views/maintenance/add.component";
import { MaintenanceTypeAddViewComponent } from "./views/maintenance/maintenanceTypes/addType.component";
import { DeviceMaintenanceViewComponent } from "./views/devices/maintenance.component";
import { DeviceDriversViewComponent } from "./views/devices/drivers.component";
import { DeviceOdoViewComponent } from "./views/devices/odo.component";
import { DeviceEngineHourViewComponent } from "./views/devices/enginehour.component";
import { DeviceFuelViewComponent } from "./views/devices/fuel.component";
import { DeviceAssetsViewComponent } from "./views/devices/assets.component";
import { DeviceGeofenceStatesViewComponent } from "./views/devices/geofenceStates.component";
import { DeviceMaintenanceV2HistoryViewComponent } from "./views/devices/maintenanceV2History.component";
import { DeviceMaintenanceV2ViewComponent } from "./views/devices/maintenanceV2.component";
import { GeofenceStatesViewComponent } from "./views/geofences/geofenceStates.component";
import { MaintenanceLogViewComponent } from "./views/maintenance/maintenanceLog.component";
import { MaintenanceDueViewComponent } from "./views/maintenance/maintenanceDue.component";
import { MaintenanceTypeDetailsViewComponent } from "./views/maintenance/maintenanceTypes/typeDetails.component";
import { MaintenanceTypesViewComponent } from "./views/maintenance/maintenanceTypes/types.component";
import { AssetTypeDetailsViewComponent } from "./views/assets/assetTypes/typeDetails.component";
import { AssetTypeAddViewComponent } from "./views/assets/assetTypes/addType.component";
import { AssetTypesViewComponent } from "./views/assets/assetTypes/types.component";
import { CustomCommandViewComponent } from "./views/devices/customCommands/customCommands.component";
import { FuelAddViewComponent } from "./views/fuel/add.component";
import { FuelThresholdsViewComponent } from "./views/fuel/fuelThresholds.component";
import { UserContactsViewComponent } from "./views/notifications/userContacts/userContacts.component";
import { UserContactAddViewComponent } from "./views/notifications/userContacts/add.component";
import { UserContactGroupAddViewComponent } from "./views/notifications/userContactGroups/add.component";
import { UserContactGroupsViewComponent } from "./views/notifications/userContactGroups/userContactGroups.component";
import { UserContactGroupDetailsViewComponent } from "./views/notifications/userContactGroups/userContactGroupDetails.component";
import { UserContactDetailsViewComponent } from "./views/notifications/userContacts/userContactDetails.component";
import { AuditLogComponent } from "./views/system/auditlog/auditLog.component";
import { AuditLogDetailsViewComponent } from "./views/system/auditlog/auditLogDetails.component";
import { ErrorLogDetailsViewComponent } from "./views/system/errorlog/errorLogDetails.component";
import { ActiveUsersComponent } from "./views/system/activeUsers/activeUsers.component";
import { FuelMapViewComponent } from "./views/fuel/map.component";
import { ChangelogViewComponent } from "./views/appviews/changelog.component";
import { DevicesStateViewComponent } from "./views/devices/state.component";
import { DevicesExternalViewComponent } from "./views/devices/external.component";
import { TriggerAddViewComponent } from "./views/notifications/triggers/add.component";
import { UserRolesViewComponent } from "./views/users/userRoles/userRoles.component";
import { UserRoleAddViewComponent } from "./views/users/userRoles/add.components";
import { ResellerLogViewComponent } from "./views/resellers/resellerLog.component";
import { TrailersAddViewComponent } from "./views/trailers/add.component";
import { TrailerDetailsViewComponent } from "./views/trailers/trailerDetails.component";
import { TrailersViewComponent } from "./views/trailers/trailers.component";
import { DeviceTrailersViewComponent } from "./views/devices/trailers.component";
import { TrailerLogViewComponent } from "./views/trailers/trailerLog.component";
import { UserRoleDetailsViewComponent } from "./views/users/userRoles/UserRoleDetails.component";
import { AccountSharingComponent } from "./views/accounts/sharing.component";
import { AccountMatrixComponent } from "./views/accounts/matrix.component";
import { WarehousesDetailsViewComponent } from "./views/warehouses/warehouseDetails.component";
import { WarehousesLogViewComponent } from "./views/warehouses/warehouseLog.component";
import { WarehousesAddViewComponent } from "./views/warehouses/add.component";
import { WarehousesViewComponent } from "./views/warehouses/warehouses.component";
import { WarehouseChartsViewComponent } from "./views/warehouses/charts.component";
import { WarehouseMessageViewComponent } from "./views/warehouses/messages.component";
import { WarehouseZonesViewComponent } from "./views/warehouses/zones.component";
import { WarehouseZoneDetailsViewComponent } from "./views/warehouses/warehouseZoneDetails.component";
import { WarehouseWaslViewComponent } from "./views/warehouses/wasl.component";
import { WarehouseEventsViewComponent } from "./views/warehouses/events.component";
import { WarehouseSensorsViewComponent } from "./views/warehouses/sensors.component";
import { ExportViewComponent } from "./views/exportImport/export.component";
import { ImportViewComponent } from "./views/exportImport/Import.component";
import { ReportingViewComponent } from "./views/reporting/executions.component";
import { ReportingDetailsViewComponent } from "./views/reporting/reportingDetails.component";
import { SubscriptionEditViewComponent } from "./views/reporting/subscriptionEdit.component";
import { NotificationLogComponent } from "./views/notifications/notificationLog/notificationLog.component";
import { NotificationLogDetailsViewComponent } from "./views/notifications/notificationLog/notificationLogDetails.component";
import { NotificationChartViewComponent } from "./views/notifications/notificationLog/chart.component";
import { DevicesCardsViewComponent } from "./views/devices/cards.component";
import { DriverCardsViewComponent } from "./views/drivers/cards.component";
import { DriverTripsViewComponent } from "./views/drivers/trips.component";
import { DriverScoreDetailsViewComponent } from "./views/drivers/scoreDetails.component";
import { DeviceProjectsViewComponent } from "./views/devices/projects.component";
import { ProjectsAddViewComponent } from "./views/projects/add.component";
import { ProjectsViewComponent } from "./views/projects/projects.component";
import { ProjectDetailsViewComponent } from "./views/projects/projectDetails.component";
import { ProjectLogViewComponent } from "./views/projects/projectLog.component";
import { DriversExternalViewComponent } from "./views/drivers/external.component";
import { TriggerEpisodesViewComponent } from "./views/notifications/triggers/episodes.component";
import { TriggerSchedulesViewComponent } from "./views/notifications/triggerSchedules/triggerSchedules.component";
import { TriggerScheduleAddViewComponent } from "./views/notifications/triggerSchedules/addTriggerSchedule.component";
import { TriggerScheduleDetailsViewComponent } from "./views/notifications/triggerSchedules/triggerScheduleDetails.component";
import { TriggerDashboardViewComponent } from "./views/notifications/triggers/triggerDashboard.component";
import { DevicesUtilizationViewComponent } from "./views/devices/utilization.component";
import { HomeLiveComponent } from "./views/appviews/homeLive.component";
import { ServiceAnnouncementsDetailsViewComponent } from "./views/settings/serviceAnnouncements/serviceAnnouncementDetails.component";
import { ServiceAnnouncementsViewComponent } from "./views/settings/serviceAnnouncements/serviceAnnouncements.component";
import { ServiceAnnouncementAddViewComponent } from "./views/settings/serviceAnnouncements/add.component";
import { ClientAnnouncementAddViewComponent } from "./views/settings/clientAnnouncements/add.component";
import { ClientAnnouncementsDetailsViewComponent } from "./views/settings/clientAnnouncements/clientAnnouncementDetails.component";
import { ClientAnnouncementsViewComponent } from "./views/settings/clientAnnouncements/clientAnnouncements.component";
import { DeviceUtilizationDetailsViewComponent } from "./views/devices/utilizationDetails.component";
import { AccountSettingsViewComponent } from "./views/accounts/settings.component";
import { SensorTemplatesViewComponent } from "./views/devices/sensorTemplates/sensorTemplates.component";
import { SensorTemplateAddViewComponent } from "./views/devices/sensorTemplates/addSensorTemplate.component";
import { SensorTemplateDetailsViewComponent } from "./views/devices/sensorTemplates/sensorTemplateDetails.component";
import { SettingStateViewerComponent } from "./views/system/stateViewer/stateViewer.component";
import { DeviceSensorsHistoryViewComponent } from "./views/devices/sensorsHistory.component";
import { AccountTrendsViewComponent } from "./views/dashboards/trends.component";
import { DeviceRoutingConfigurationComponent } from "./views/devices/deviceRoutingConfiguration.component";
import { DeviceEpisodeDetailsViewComponent } from "./views/devices/episodeDetails.component";
import { TriggerLogViewComponentLogViewComponent } from "./views/notifications/triggers/triggerLog.component";
import { AccountUtilizationViewComponent } from "./views/dashboards/utilization.component";
import { IssuesViewComponent } from "./views/dashboards/issues.component";
import { SharingComponent } from "./views/appviews/sharing.component";
import { TokenComponent } from "./views/appviews/tokens.component";
import { ProfileComponent } from "./views/appviews/profile.component";
import { SystemGeneralComponent } from "./views/system/general.component";
import { DriverAssignmentsViewComponent } from "./views/drivers/assignments.component";
import { DeviceAssignmentsViewComponent } from "./views/devices/assignments.component";
import { WebhookEndpointsViewComponent } from "./views/system/webhookEndpoints/webhookEndpoints.component";
import { WebhookEndpointAddViewComponent } from "./views/system/webhookEndpoints/addWebhookEndpoint.component";
import { WebhookEndpointDetailsViewComponent } from "./views/system/webhookEndpoints/webhookEndpointDetails.component";
import { ReportingSubscriptionsViewComponent } from "./views/reporting/subscriptions.component";
import { SubscriptionDetailsViewComponent } from "./views/reporting/subscriptionDetails.component";
import { SubscriptionLogViewComponent } from "./views/reporting/subscriptionLog.component";
import { ReportingTemplatesViewComponent } from "./views/reporting/templates.component";
import { IssuesLatencyViewComponent } from "./views/dashboards/latency.component";
import { DeviceSettingChangesViewComponent } from "./views/devices/changesOverview.component";
import { CarSharingViewComponent } from "./views/carsharing/dashboard.component";
import { CarSharingDamagesViewComponent } from "./views/carsharing/damages.component";
import { CarSharingGaragesViewComponent } from "./views/carsharing/garages.component";
import { CarSharingGaragesDetailsViewComponent } from "./views/carsharing/garageDetails.component";
import { CarSharingGaragesAddViewComponent } from "./views/carsharing/garageAdd.component";
import { AccountAssetScoreViewComponent } from "./views/ecodriving/assetScore.component";
import { AccountDriverScoreViewComponent } from "./views/ecodriving/driverScore.component";
import { AccountDriverScoreSettingsViewComponent } from "./views/ecodriving/driverScoreSettings.component";
import { AccountEquipmentViewComponent } from "./views/equipment/equipment.component";
import { EquipmentDayViewViewComponent } from "./views/equipment/dayview.component";
import { DeviceRoutesViewComponent } from "./views/devices/routes.component";
import { DeviceDayViewViewComponent } from "./views/devices/dayview.component";
import { DriverDayViewViewComponent } from "./views/drivers/dayview.component";
import { GeofenceGroupLogViewComponent } from "./views/geofences/geofenceGroupLog.component";
import { AssetGroupLogViewComponent } from "./views/assets/assetGroupLog.component";
import { DriverGroupLogViewComponent } from "./views/drivers/driverGroupLog.component";
import { DriverTripsDayViewViewComponent } from "./views/drivers/dayviewTrips.component";
import { TriggerNotificationsViewComponent } from "./views/notifications/triggers/notifications.component";
import { FuelDashboardViewComponent } from "./views/fuel/dashboard.component";
import { FleetOverviewComponent } from "./views/overview/overview.component";
import { NotificationsLiveViewComponent } from "./views/notifications/notificationsLive.component";
import { ErrorLogComponent } from "./views/system/errorlog/errorlog.component";
import { MyAccountComponent } from "./views/appviews/myAccount.component";
import { DevicesTripsOverViewComponent } from "./views/devices/tripsOverview.component";
import { HomeHistoryComponent } from "./views/appviews/homeHistory.component";
import { HomeComponent } from "./views/appviews/home.component";
import { NotificationsViewComponent } from "./views/notifications/notifications.component";
import { NotificationsSettingsViewComponent } from "./views/notifications/notificationsSettings.component";
import { AccountNotificationsViewComponent } from "./views/accounts/notifications.component";
import { AccountCostsViewComponent } from "./views/dashboards/costs.component";
import { DeviceGeofenceEventsViewComponent } from "./views/devices/geofenceEvents.component";
import { DeviceGeofenceEpisodesViewComponent } from "./views/devices/geofenceEpisodes.component";
import { GeofenceEventsViewComponent } from "./views/geofences/geofenceEvents.component";
import { GeofenceEpisodesViewComponent } from "./views/geofences/geofenceEpisodes.component";
import { AuthGuardSharing } from "./auth-guard-sharing";
import { ReportingSharedDetailsViewComponent } from "./views/reporting/reportingSharedDetails.component";
import { DeviceShareDetailsViewComponent } from "./views/devices/deviceShareDetails.component";
import { DeviceSharedTripsViewComponent } from "./views/devices/sharedTrips.component";
import { DeviceSharedTripDetailsViewComponent } from "./views/devices/sharedTripDetails.component";
import { RedirectComponent } from "./views/appviews/redirect.component";
import { ReportingDashboardComponent } from "./views/reporting/dashboard.component";
import { ShareTokensComponent } from "./views/appviews/shareTokens.component";
import { CustomCommandAddViewComponent } from "./views/devices/customCommands/addCommand.component";
import { CustomCommandDetailsViewComponent } from "./views/devices/customCommands/commandDetails.component";
import { MaintenanceV2DashboardViewComponent } from "./views/maintenanceV2/dashboard.component";
import { CockpitComponent } from "./views/cockpit/cockpit.component";
import { HierarchyViewComponent } from "./views/system/hierarchy/dashboard.component";
import { HierarchyGroupsViewComponent } from "./views/system/hierarchy/hierarchyGroups.component";
import { HierarchyGroupDetailsViewComponent } from "./views/system/hierarchy/hierarchyGroupDetails.component";
import { HierarchyGroupsAddViewComponent } from "./views/system/hierarchy/addhierarchyGroup.component";
import { MaintenanceServiceItemViewComponent } from "./views/maintenanceV2/serviceItem/serviceItems.component";
import { MaintenanceServiceItemAddViewComponent } from "./views/maintenanceV2/serviceItem/addServiceItem.component";
import { MaintenanceServiceItemDetailsViewComponent } from "./views/maintenanceV2/serviceItem/serviceItemDetails.component";
import { MaintenanceWorkOrderViewComponent } from "./views/maintenanceV2/workorders/workOrders.component";
import { MaintenanceWorkOrderAddViewComponent } from "./views/maintenanceV2/workorders/addWorkOrder.component";
import { MaintenanceWorkOrderDetailsViewComponent } from "./views/maintenanceV2/workorders/workOrderDetails.component";
import { MaintenanceV2DueViewComponent } from "./views/maintenanceV2/maintenancedue/maintenanceDue.component";

export const ROUTES: Routes = [
  // Main redirect
  { path: "", redirectTo: "Home/Default", pathMatch: "full" },

  // App views
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      title: "Home",
    },
    children: [
      {
        path: "Home",
        component: HomeComponent,
        data: {
          title: "Home",
        },
      },
      {
        path: "Home/Default",
        component: HomeComponent,
        data: {
          title: "Home",
        },
      },
      {
        path: "Home/Live",
        component: HomeLiveComponent,
        data: {
          title: "Home live",
        },
      },
      {
        path: "Home/History",
        component: HomeHistoryComponent,
        data: {
          title: "Home History",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "FleetOverview",
        component: FleetOverviewComponent,
        data: {
          title: "FleetOverview",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Cockpit",
        component: CockpitComponent,
        data: {
          title: "Cockpit",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Dashboard/Default",
        component: HomeLiveComponent,
        data: {
          title: "Dashboard",
        },
      },
      {
        path: "Dashboard",
        component: DashboardComponent,
        data: {
          title: "Dashboard",
        },
      },
      {
        path: "Dashboard/Dashboard",
        component: DashboardComponent,
        data: {
          title: "Dashboard",
        },
      },
      {
        path: "Dashboard/Issues",
        component: IssuesViewComponent,
        data: {
          title: "Issues",
        },
      },
      {
        path: "Dashboard/Latency",
        component: IssuesLatencyViewComponent,
        data: {
          title: "Issues latency",
        },
      },
      {
        path: "Dashboard/Utilization",
        component: AccountUtilizationViewComponent,
        data: {
          title: "Utilization Dashboard",
        },
      },
      {
        path: "Dashboard/Costs",
        component: AccountCostsViewComponent,
        data: {
          title: "Costs",
        },
      },
      {
        path: "Dashboard/Trends",
        component: AccountTrendsViewComponent,
        data: {
          title: "Trends Dashboard",
        },
      },
      {
        path: "EcoDriving",
        component: AccountDriverScoreViewComponent,
        data: {
          title: "DriverScore Dashboard",
        },
      },
      {
        path: "EcoDriving/Drivers",
        component: AccountDriverScoreViewComponent,
        data: {
          title: "DriverScore Dashboard",
        },
      },
      {
        path: "EcoDriving/Settings",
        component: AccountDriverScoreSettingsViewComponent,
        data: {
          title: "DriverScore Settings",
        },
      },
      {
        path: "EcoDriving/Assets",
        component: AccountAssetScoreViewComponent,
        data: {
          title: "AssetScore Home",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Equipment/Dashboard",
        component: AccountEquipmentViewComponent,
        data: {
          title: "Equipment Home",
        },
      },
      {
        path: "Equipment/DayView",
        component: EquipmentDayViewViewComponent,
        data: {
          title: "Equipment DayView",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "CarSharing/Dashboard",
        component: CarSharingViewComponent,
        data: {
          title: "Dashboard",
        },
      },
      {
        path: "CarSharing/Damages",
        component: CarSharingDamagesViewComponent,
        data: {
          title: "Damages",
        },
      },
      {
        path: "CarSharing/Garages",
        component: CarSharingGaragesViewComponent,
        data: {
          title: "Garages",
        },
      },
      {
        path: "CarSharing/Garages/Add",
        component: CarSharingGaragesAddViewComponent,
        data: {
          title: "Garages Add",
        },
      },
      {
        path: "CarSharing/GarageDetails/Index/:id",
        component: CarSharingGaragesDetailsViewComponent,
        data: {
          title: "Details Garage",
        },
      },
      // {
      //   path: 'GarageDetails/Log/:id', component: GarageLogViewComponent, data: {
      //     title: 'Log Garage'
      //   },
      // },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Search",
        component: SearchViewComponent,
        data: {
          title: "Search",
        },
      },
      {
        path: "Tags",
        component: TagsViewComponent,
        data: {
          title: "Tags",
        },
      },
      {
        path: "Tags/:id",
        component: TagListViewComponent,
        data: {
          title: "Tag details",
        },
      },
      {
        path: "System/Tags",
        component: TagsViewComponent,
        data: {
          title: "Tags",
        },
      },
      {
        path: "System/Tags/:id",
        component: TagListViewComponent,
        data: {
          title: "Tag details",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "AssetGroups",
        component: AssetGroupViewComponent,
        data: {
          title: "Assetgroup overview",
        },
      },
      {
        path: "AssetGroups/Add",
        component: AssetGroupAddViewComponent,
        data: {
          title: "Add assetgroup",
        },
      },
      {
        path: "AssetGroups/Overview",
        component: AssetGroupViewComponent,
        data: {
          title: "Assetgroup overview",
        },
      },
      {
        path: "Drivers",
        component: DriversViewComponent,
        data: {
          title: "Driver overview",
        },
      },
      {
        path: "Drivers/Add",
        component: DriversAddViewComponent,
        data: {
          title: "Add driver",
        },
      },
      {
        path: "Drivers/Overview",
        component: DriversViewComponent,
        data: {
          title: "Driver overview",
        },
      },
      {
        path: "Drivers/Cards",
        component: DriverCardsViewComponent,
        data: {
          title: "Driver cards",
        },
      },
      {
        path: "Drivers/Assignments",
        component: DriverAssignmentsViewComponent,
        data: {
          title: "Driver assignments",
        },
      },
      {
        path: "Drivers/DayViewAssignments",
        component: DriverDayViewViewComponent,
        data: {
          title: "Driver day view",
        },
      },
      {
        path: "Drivers/DayViewTrips",
        component: DriverTripsDayViewViewComponent,
        data: {
          title: "Driver day view trips",
        },
      },
      {
        path: "Drivers/External",
        component: DriversExternalViewComponent,
        data: {
          title: "Driver external",
        },
      },
      {
        path: "DriverGroups",
        component: DriverGroupViewComponent,
        data: {
          title: "Drivergroup overview",
        },
      },
      {
        path: "DriverGroups/Add",
        component: DriverGroupAddViewComponent,
        data: {
          title: "Add drivergroup",
        },
      },
      {
        path: "DriverGroups/Overview",
        component: DriverGroupViewComponent,
        data: {
          title: "Drivergroup overview",
        },
      },
      {
        path: "Trailers",
        component: TrailersViewComponent,
        data: {
          title: "Trailer overview",
        },
      },
      {
        path: "Trailers/Add",
        component: TrailersAddViewComponent,
        data: {
          title: "Add trailer",
        },
      },
      {
        path: "Trailers/Overview",
        component: TrailersViewComponent,
        data: {
          title: "Trailers overview",
        },
      },
      {
        path: "Projects",
        component: ProjectsViewComponent,
        data: {
          title: "Project overview",
        },
      },
      {
        path: "Projects/Add",
        component: ProjectsAddViewComponent,
        data: {
          title: "Add project",
        },
      },
      {
        path: "Projects/Overview",
        component: ProjectsViewComponent,
        data: {
          title: "Projects overview",
        },
      },
      {
        path: "Warehouses",
        component: WarehousesViewComponent,
        data: {
          title: "Warehouses overview",
        },
      },
      {
        path: "Warehouses/Add",
        component: WarehousesAddViewComponent,
        data: {
          title: "Add Warehouse",
        },
      },
      {
        path: "Warehouses/Overview",
        component: WarehousesViewComponent,
        data: {
          title: "Warehouses overview",
        },
      },
      {
        path: "Geofences",
        component: GeofencesViewComponent,
        data: {
          title: "Geofence overview",
        },
      },
      {
        path: "Geofences/Add",
        component: GeofencesAddViewComponent,
        data: {
          title: "Add geofence",
        },
      },
      {
        path: "Geofences/Overview",
        component: GeofencesViewComponent,
        data: {
          title: "Geofence overview",
        },
      },
      {
        path: "GeofenceGroups",
        component: GeofenceGroupViewComponent,
        data: {
          title: "Geofencegroup overview",
        },
      },
      {
        path: "GeofenceGroups/Add",
        component: GeofenceGroupAddViewComponent,
        data: {
          title: "Add geofence group",
        },
      },
      {
        path: "GeofenceGroups/Overview",
        component: GeofenceGroupViewComponent,
        data: {
          title: "Geofencegroup overview",
        },
      },
      {
        path: "Devices",
        component: DevicesViewComponent,
        data: {
          title: "Tracker overview",
        },
      },
      {
        path: "Devices/Add",
        component: DeviceAddViewComponent,
        data: {
          title: "Add tracker",
        },
      },
      {
        path: "Devices/Overview",
        component: DevicesViewComponent,
        data: {
          title: "Tracker overview",
        },
      },
      {
        path: "Devices/Assignments",
        component: DeviceAssignmentsViewComponent,
        data: {
          title: "Tracker assignments",
        },
      },
      {
        path: "Devices/Trips",
        component: DevicesTripsOverViewComponent,
        data: {
          title: "Tracker trips",
        },
      },
      {
        path: "Devices/DayView",
        component: DeviceDayViewViewComponent,
        data: {
          title: "Tracker assignments",
        },
      },
      {
        path: "Devices/Map",
        component: DevicesMapViewComponent,
        data: {
          title: "Tracker map",
        },
      },
      {
        path: "Devices/DeviceRoutingConfiguration",
        component: DeviceRoutingConfigurationComponent,
        data: {
          title: "DeviceRoutingConfiguration",
        },
      },
      {
        path: "Devices/State",
        component: DevicesStateViewComponent,
        data: {
          title: "Tracker state",
        },
      },
      {
        path: "Devices/Utilization",
        component: DevicesUtilizationViewComponent,
        data: {
          title: "Tracker utilization",
        },
      },
      {
        path: "Devices/Cards",
        component: DevicesCardsViewComponent,
        data: {
          title: "Tracker cards",
        },
      },
      {
        path: "Devices/Changes",
        component: DeviceSettingChangesViewComponent,
        data: {
          title: "Tracker settings changes",
        },
      },
      {
        path: "Devices/External",
        component: DevicesExternalViewComponent,
        data: {
          title: "Tracker external",
        },
      },
      {
        path: "Devices/AssetTypes",
        component: AssetTypesViewComponent,
        data: {
          title: "Asset types",
        },
      },
      {
        path: "Devices/AssetTypes/Overview",
        component: AssetTypesViewComponent,
        data: {
          title: "Asset types",
        },
      },
      {
        path: "Devices/AssetTypes/Add",
        component: AssetTypeAddViewComponent,
        data: {
          title: "Asset type add",
        },
      },
      {
        path: "Devices/CustomCommands/Overview",
        component: CustomCommandViewComponent,
        data: {
          title: "Custom commands",
        },
      },
      {
        path: "Devices/CustomCommands/Add",
        component: CustomCommandAddViewComponent,
        data: {
          title: "Custom command add",
        },
      },
      {
        path: "Devices/DeviceTypes",
        component: DevicesTypeViewComponent,
        data: {
          title: "Hardwaretype overview",
        },
      },
      {
        path: "Devices/DeviceTypes/Overview",
        component: DevicesTypeViewComponent,
        data: {
          title: "Hardwaretype overview",
        },
      },
      {
        path: "Devices/Routes",
        component: DeviceRoutesViewComponent,
        data: {
          title: "Route Tracker",
        },
      },
      {
        path: "Users",
        component: UsersViewComponent,
        data: {
          title: "Users overview",
        },
      },
      {
        path: "Users/Overview",
        component: UsersViewComponent,
        data: {
          title: "Users overview",
        },
      },
      {
        path: "Accounts",
        component: AccountsViewComponent,
        data: {
          title: "Accounts overview",
        },
      },
      {
        path: "Accounts/Add",
        component: AccountsAddViewComponent,
        data: {
          title: "Add account",
        },
      },
      {
        path: "Accounts/Overview",
        component: AccountsViewComponent,
        data: {
          title: "Accounts overview",
        },
      },
      {
        path: "Notifications/TriggerSchedules/Overview",
        component: TriggerSchedulesViewComponent,
        data: {
          title: "Trigger Schedules",
        },
      },
      {
        path: "Notifications/TriggerScheduleDetails/Add",
        component: TriggerScheduleAddViewComponent,
        data: {
          title: "Trigger schedule add",
        },
      },
      {
        path: "System/WebhookEndpoints/Overview",
        component: WebhookEndpointsViewComponent,
        data: {
          title: "Webhook Endpoints",
        },
      },
      {
        path: "System/WebhookEndpointDetails/Add",
        component: WebhookEndpointAddViewComponent,
        data: {
          title: "Webhook Endpoint add",
        },
      },
      {
        path: "Devices/SensorTemplates/Overview",
        component: SensorTemplatesViewComponent,
        data: {
          title: "Sensor Templates",
        },
      },
      {
        path: "Devices/SensorTemplateDetails/Add",
        component: SensorTemplateAddViewComponent,
        data: {
          title: "Sensor Templates add",
        },
      },
      {
        path: "Resellers",
        component: ResellersViewComponent,
        data: {
          title: "Resellers overview",
        },
      },
      {
        path: "Resellers/Add",
        component: ResellersAddViewComponent,
        data: {
          title: "Add reseller",
        },
      },
      {
        path: "Resellers/Overview",
        component: ResellersViewComponent,
        data: {
          title: "Resellers overview",
        },
      },
      {
        path: "Notifications/Triggers",
        component: TriggersViewComponent,
        data: {
          title: "Trigger overview",
        },
      },
      {
        path: "Notifications/Triggers/Overview",
        component: TriggersViewComponent,
        data: {
          title: "Trigger overview",
        },
      },
      {
        path: "Fuel",
        component: FuelViewComponent,
        data: {
          title: "Fuel overview",
        },
      },
      {
        path: "Fuel/Dashboard",
        component: FuelDashboardViewComponent,
        data: {
          title: "Fuel Dashboard",
        },
      },
      {
        path: "Fuel/Overview",
        component: FuelViewComponent,
        data: {
          title: "Fuel overview",
        },
      },
      {
        path: "Fuel/Thresholds",
        component: FuelThresholdsViewComponent,
        data: {
          title: "Fuel thresholds",
        },
      },
      {
        path: "Fuel/Map",
        component: FuelMapViewComponent,
        data: {
          title: "Fuel map",
        },
      },
      {
        path: "Devices/DeviceTypes",
        component: DevicesTypeViewComponent,
        data: {
          title: "Hardwaretype overview",
        },
      },
      {
        path: "Settings",
        component: SettingsComponent,
        data: {
          title: "Settings",
        },
      },
      {
        path: "Profile",
        component: ProfileComponent,
        data: {
          title: "Profile",
        },
      },
      {
        path: "MyAccount",
        component: MyAccountComponent,
        data: {
          title: "MyAccount",
        },
      },
      {
        path: "Settings/System",
        component: SettingsComponent,
        data: {
          title: "System",
        },
      },
      {
        path: "System/Health",
        component: SystemGeneralComponent,
        data: {
          title: "System",
        },
      },
      {
        path: "Settings/Language",
        component: LanguageSettingsComponent,
        data: {
          title: "Language",
        },
      },
      {
        path: "Settings/Theme",
        component: ThemeSettingsComponent,
        data: {
          title: "Theme",
        },
      },
      {
        path: "System/ErrorLog",
        component: ErrorLogComponent,
        data: {
          title: "Error log",
        },
      },
      {
        path: "System/Hierarchy/Dashboard",
        component: HierarchyViewComponent,
        data: {
          title: "Hierarchy dashboard",
        },
      },
      {
        path: "System/HierarchyGroups/Overview",
        component: HierarchyGroupsViewComponent,
        data: {
          title: "Hierarchy groups",
        },
      },
      {
        path: "System/HierarchyGroupDetails/Index/:id",
        component: HierarchyGroupDetailsViewComponent,
        data: {
          title: "Hierarchy group details",
        },
      },
      {
        path: "System/HierarchyGroupDetails/Add",
        component: HierarchyGroupsAddViewComponent,
        data: {
          title: "Hierarchy group add",
        },
      },
      {
        path: "System/StateViewer",
        component: SettingStateViewerComponent,
        data: {
          title: "StateViewer",
        },
      },
      {
        path: "System/StateViewer",
        component: SettingStateViewerComponent,
        data: {
          title: "StateViewer",
        },
      },
      {
        path: "System/AuditLog",
        component: AuditLogComponent,
        data: {
          title: "Audit log",
        },
      },
      {
        path: "System/AuditLog/Overview",
        component: AuditLogComponent,
        data: {
          title: "Audit log",
        },
      },
      {
        path: "Notifications/Log",
        component: NotificationLogComponent,
        data: {
          title: "Notification log",
        },
      },
      {
        path: "Notifications/Log/Overview",
        component: NotificationLogComponent,
        data: {
          title: "Notification log",
        },
      },
      {
        path: "Settings/General",
        component: SettingsGeneralComponent,
        data: {
          title: "General",
        },
      },
      {
        path: "Shared",
        component: SharingComponent,
        data: {
          title: "Shared",
        },
      },
      {
        path: "ShareTokens",
        component: ShareTokensComponent,
        data: {
          title: "ShareTokens",
        },
      },
      {
        path: "Tokens",
        component: TokenComponent,
        data: {
          title: "Tokens",
        },
      },
      {
        path: "System/ActiveUsers",
        component: ActiveUsersComponent,
        data: {
          title: "Active users",
        },
      },
      {
        path: "System/ActiveUsers/Overview",
        component: ActiveUsersComponent,
        data: {
          title: "Active users",
        },
      },
      {
        path: "Settings/ServiceAnnouncements",
        component: ServiceAnnouncementsViewComponent,
        data: {
          title: "Service announcements",
        },
      },
      {
        path: "Settings/ServiceAnnouncements/Overview",
        component: ServiceAnnouncementsViewComponent,
        data: {
          title: "Service announcements",
        },
      },
      {
        path: "Settings/ServiceAnnouncementDetails/Add",
        component: ServiceAnnouncementAddViewComponent,
        data: {
          title: "Service announcement add",
        },
      },
      {
        path: "Settings/ClientAnnouncements",
        component: ClientAnnouncementsViewComponent,
        data: {
          title: "Client announcements",
        },
      },
      {
        path: "Settings/ClientAnnouncements/Overview",
        component: ClientAnnouncementsViewComponent,
        data: {
          title: "Client announcements",
        },
      },
      {
        path: "Settings/ClientAnnouncementDetails/Add",
        component: ClientAnnouncementAddViewComponent,
        data: {
          title: "Client announcement add",
        },
      },
      {
        path: "System/Batch",
        component: BatchViewComponent,
        data: {
          title: "Batch",
        },
      },
      {
        path: "System/Batch/Overview",
        component: BatchViewComponent,
        data: {
          title: "Batch overview",
        },
      },
      {
        path: "System",
        component: SystemGeneralComponent,
        data: {
          title: "System",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Maintenances",
        component: MaintenanceViewComponent,
        data: {
          title: "Maintenance overview",
        },
      },
      {
        path: "Maintenances/Add",
        component: MaintenanceAddViewComponent,
        data: {
          title: "Maintenance add",
        },
      },
      {
        path: "Maintenances/Overview",
        component: MaintenanceViewComponent,
        data: {
          title: "Maintenance overview",
        },
      },
      {
        path: "Maintenances/Due",
        component: MaintenanceDueViewComponent,
        data: {
          title: "Maintenance Due overview",
        },
      },
      {
        path: "Maintenances/MaintenanceTypes",
        component: MaintenanceTypesViewComponent,
        data: {
          title: "Maintenance types",
        },
      },
      {
        path: "Maintenances/MaintenanceTypes/Overview",
        component: MaintenanceTypesViewComponent,
        data: {
          title: "Maintenance types",
        },
      },
      {
        path: "Maintenances/MaintenanceTypes/Add",
        component: MaintenanceTypeAddViewComponent,
        data: {
          title: "Maintenance type add",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Maintenances/MaintenanceTypeDetails/Index/:id",
        component: MaintenanceTypeDetailsViewComponent,
        data: {
          title: "Details MaintenanceType",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "MaintenanceDetails/Index/:id",
        component: MaintenanceDetailsViewComponent,
        data: {
          title: "Details Maintenance",
        },
      },
      {
        path: "MaintenanceDetails/Log/:id",
        component: MaintenanceLogViewComponent,
        data: {
          title: "Log Maintenance",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "MaintenancesV2",
        component: MaintenanceV2DashboardViewComponent,
        data: {
          title: "Maintenance overview",
        },
      },
      {
        path: "MaintenancesV2/Dashboard",
        component: MaintenanceV2DashboardViewComponent,
        data: {
          title: "Maintenance overview",
        },
      },
      {
        path: "MaintenancesV2/ServiceItems",
        component: MaintenanceServiceItemViewComponent,
        data: {
          title: "Service items",
        },
      },
      {
        path: "MaintenancesV2/ServiceItems/Overview",
        component: MaintenanceServiceItemViewComponent,
        data: {
          title: "Service items",
        },
      },
      {
        path: "MaintenancesV2/ServiceItems/Add",
        component: MaintenanceServiceItemAddViewComponent,
        data: {
          title: "Service item add",
        },
      },
      {
        path: "MaintenancesV2/ServiceItems/Details/Index/:id",
        component: MaintenanceServiceItemDetailsViewComponent,
        data: {
          title: "Service item details",
        },
      },
      {
        path: "MaintenancesV2/WorkOrders/Overview",
        component: MaintenanceWorkOrderViewComponent,
        data: {
          title: "Work orders",
        },
      },
      {
        path: "MaintenancesV2/WorkOrders/Add",
        component: MaintenanceWorkOrderAddViewComponent,
        data: {
          title: "Work order add",
        },
      },
      {
        path: "MaintenancesV2/WorkOrders/Details/Index/:id",
        component: MaintenanceWorkOrderDetailsViewComponent,
        data: {
          title: "Work order details",
        },
      },
      {
        path: "MaintenancesV2/Due",
        component: MaintenanceV2DueViewComponent,
        data: {
          title: "Maintenance due",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Reporting",
        component: ReportingDashboardComponent,
        data: {
          title: "Reporting overview",
        },
      },
      {
        path: "Reporting/Dashboard",
        component: ReportingDashboardComponent,
        data: {
          title: "Reporting dashboard",
        },
      },
      {
        path: "Reporting/Executions",
        component: ReportingViewComponent,
        data: {
          title: "Reporting executions",
        },
      },
      {
        path: "Reporting/Subscriptions",
        component: ReportingSubscriptionsViewComponent,
        data: {
          title: "Reporting subscriptions",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Reporting/Templates",
        component: ReportingTemplatesViewComponent,
        data: {
          title: "Reporting templates",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Reporting/Create",
        component: ReportingCreateViewComponent,
        data: {
          title: "Create new report",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Reporting/Edit/:id",
        component: SubscriptionEditViewComponent,
        data: {
          title: "Edit subscription",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "ReportDetails/Index/:id",
        component: ReportingDetailsViewComponent,
        data: {
          title: "Details Report",
        },
      },
    ],
  },
  {
    path: "",
    component: ShareLayoutComponent,
    canActivate: [AuthGuardSharing],
    canActivateChild: [AuthGuardSharing],
    children: [
      {
        path: "ReportDetails/Shared/:id",
        component: ReportingSharedDetailsViewComponent,
        data: {
          title: "Details Report",
        },
      },
      {
        path: "DeviceDetails/SharedTrips/:id",
        component: DeviceSharedTripsViewComponent,
        data: {
          title: "Trips Shared Tracker",
        },
      },
      {
        path: "DeviceDetails/SharedTripDetails/:id",
        component: DeviceSharedTripDetailsViewComponent,
        data: {
          title: "Trips Shared Tracker",
        },
      },
      {
        path: "DeviceDetails/Shared/:id",
        component: DeviceShareDetailsViewComponent,
        data: {
          title: "Details Shared Tracker",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "ReportSubscriptionDetails/Index/:id",
        component: SubscriptionDetailsViewComponent,
        data: {
          title: "Subscription Details Report",
        },
      },
      {
        path: "ReportSubscriptionDetails/Log/:id",
        component: SubscriptionLogViewComponent,
        data: {
          title: "Log Subscription",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "System/Export",
        component: ExportViewComponent,
        data: {
          title: "Export",
        },
      },
      {
        path: "System/Import",
        component: ImportViewComponent,
        data: {
          title: "Import",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Devices/AssetTypeDetails/Index/:id",
        component: AssetTypeDetailsViewComponent,
        data: {
          title: "Details AssetType",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "System/ErrorLogDetails/Index/:id",
        component: ErrorLogDetailsViewComponent,
        data: {
          title: "Error log details",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "System/AuditLogDetails/Index/:id",
        component: AuditLogDetailsViewComponent,
        data: {
          title: "Details AuditLog",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Notifications/LogDetails/Index/:id",
        component: NotificationLogDetailsViewComponent,
        data: {
          title: "Details NotificationLog",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Notifications/Log/Chart",
        component: NotificationChartViewComponent,
        data: {
          title: "Notification chart",
        },
      },
    ],
  },

  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "AssetGroupDetails/Index/:id",
        component: AssetGroupDetailsViewComponent,
        data: {
          title: "Details Assetgroup",
        },
      },
      {
        path: "AssetGroupDetails/Log/:id",
        component: AssetGroupLogViewComponent,
        data: {
          title: "Log AssetGroup",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Notifications/UserContactGroupDetails/Index/:id",
        component: UserContactGroupDetailsViewComponent,
        data: {
          title: "Details UserContactGroups",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Notifications/UserContactDetails/Index/:id",
        component: UserContactDetailsViewComponent,
        data: {
          title: "Details UserContact",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Settings/ClientAnnouncementDetails/Index/:id",
        component: ClientAnnouncementsDetailsViewComponent,
        data: {
          title: "Details Client Announcement",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Settings/ServiceAnnouncementDetails/Index/:id",
        component: ServiceAnnouncementsDetailsViewComponent,
        data: {
          title: "Details Service Announcement",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Users/UserRoleDetails/Index/:id",
        component: UserRoleDetailsViewComponent,
        data: {
          title: "Details UserRole ",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Settings/ArchiveRetentionRules",
        component: ArchiveRetentionRuleViewComponent,
        data: {
          title: "ArchiveRetentionRule overview",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Settings/ArchiveRetentionRules/Overview",
        component: ArchiveRetentionRuleViewComponent,
        data: {
          title: "ArchiveRetentionRule overview",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Notifications/TriggerScheduleDetails/Index/:id",
        component: TriggerScheduleDetailsViewComponent,
        data: {
          title: "Details TriggerSchedule",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Devices/SensorTemplateDetails/Index/:id",
        component: SensorTemplateDetailsViewComponent,
        data: {
          title: "Details Sensor template",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Devices/CustomCommands/Details/Index/:id",
        component: CustomCommandDetailsViewComponent,
        data: {
          title: "Details custom command",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "System/WebhookEndpointDetails/Index/:id",
        component: WebhookEndpointDetailsViewComponent,
        data: {
          title: "Details WebhookEndpoint",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Map",
        component: DevicesMapViewComponent,
        data: {
          title: "Map",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "AssetDetails/Index/:id",
        component: DeviceDetailsViewComponent,
        data: {
          title: "Details Tracker",
        },
      },
      {
        path: "DeviceDetails/Index/:id",
        component: DeviceDetailsViewComponent,
        data: {
          title: "Details Tracker",
        },
      },
      {
        path: "DeviceDetails/Shared/:id",
        component: DeviceShareDetailsViewComponent,
        data: {
          title: "Details Tracker",
        },
      },
      {
        path: "DeviceDetails/Assets/:id",
        component: DeviceAssetsViewComponent,
        data: {
          title: "Assets Tracker",
        },
      },
      {
        path: "DeviceDetails/Drivers/:id",
        component: DeviceDriversViewComponent,
        data: {
          title: "Drivers Tracker",
        },
      },
      {
        path: "DeviceDetails/Trailers/:id",
        component: DeviceTrailersViewComponent,
        data: {
          title: "Trailers Tracker",
        },
      },
      {
        path: "DeviceDetails/Projects/:id",
        component: DeviceProjectsViewComponent,
        data: {
          title: "Projects Tracker",
        },
      },
      {
        path: "DeviceDetails/Log/:id",
        component: DeviceLogViewComponent,
        data: {
          title: "Log Tracker",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "DeviceDetails/SensorSettings/:id",
        component: DeviceSensorSettingsViewComponent,
        data: {
          title: "Sensors Settings Tracker",
        },
      },
      {
        path: "DeviceDetails/SensorsHistory/:id",
        component: DeviceSensorsHistoryViewComponent,
        data: {
          title: "Sensors History Tracker",
        },
      },
      {
        path: "DeviceDetails/Notifications/:id",
        component: DeviceNotificationsViewComponent,
        data: {
          title: "Notifications Tracker",
        },
      },
      {
        path: "DeviceDetails/Episodes/:id",
        component: DeviceEpisodesViewComponent,
        data: {
          title: "Episodes Tracker",
        },
      },
      {
        path: "DeviceDetails/EpisodeDetails/:id",
        component: DeviceEpisodeDetailsViewComponent,
        data: {
          title: "Episodes details",
        },
      },
      {
        path: "DeviceDetails/GeofenceStates/:id",
        component: DeviceGeofenceStatesViewComponent,
        data: {
          title: "GeofenceStates Tracker",
        },
      },
      {
        path: "DeviceDetails/GeofenceEvents/:id",
        component: DeviceGeofenceEventsViewComponent,
        data: {
          title: "GeofenceEvents Tracker",
        },
      },
      {
        path: "DeviceDetails/GeofenceEpisodes/:id",
        component: DeviceGeofenceEpisodesViewComponent,
        data: {
          title: "GeofenceEpisodes Tracker",
        },
      },
      {
        path: "DeviceDetails/Trips/:id",
        component: DeviceTripsViewComponent,
        data: {
          title: "Trips Tracker",
        },
      },
      {
        path: "DeviceDetails/Utilization/:id",
        component: DeviceUtilizationDetailsViewComponent,
        data: {
          title: "Utilization Tracker",
        },
      },
      {
        path: "DeviceDetails/Messages/:id",
        component: DeviceMessageViewComponent,
        data: {
          title: "Messages Tracker",
        },
      },
      {
        path: "DeviceDetails/RawData/:id",
        component: DeviceRawDataViewComponent,
        data: {
          title: "Raw Data Tracker",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "DeviceDetails/Settings/:id",
        component: DeviceSettingsViewComponent,
        data: {
          title: "Settings Tracker",
        },
      },
      {
        path: "DeviceDetails/Changes/:id",
        component: DeviceChangesViewComponent,
        data: {
          title: "Change Tracker",
        },
      },
      {
        path: "DeviceDetails/Immobilization/:id",
        component: DeviceImmobilizationViewComponent,
        data: {
          title: "Immobilization Tracker",
        },
      },
      {
        path: "DeviceDetails/Maintenance/:id",
        component: DeviceMaintenanceViewComponent,
        data: {
          title: "Maintenance Tracker",
        },
      },
      {
        path: "DeviceDetails/MaintenanceV2/Maintenance/:id",
        component: DeviceMaintenanceV2ViewComponent,
        data: {
          title: "Maintenance V2 Tracker",
        },
      },
      {
        path: "DeviceDetails/MaintenanceV2/History/:id",
        component: DeviceMaintenanceV2HistoryViewComponent,
        data: {
          title: "Maintenance V2 Tracker",
        },
      },
      {
        path: "DeviceDetails/Fuel/:id",
        component: DeviceFuelViewComponent,
        data: {
          title: "Fuel Tracker",
        },
      },
      {
        path: "DeviceDetails/Odo/:id",
        component: DeviceOdoViewComponent,
        data: {
          title: "Odo Tracker",
        },
      },
      {
        path: "DeviceDetails/EngineHour/:id",
        component: DeviceEngineHourViewComponent,
        data: {
          title: "EngineHour Tracker",
        },
      },
      {
        path: "DeviceDetails/External/:id",
        component: DeviceWaslViewComponent,
        data: {
          title: "Wasl Device",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "DriverDetails/Index/:id",
        component: DriverDetailsViewComponent,
        data: {
          title: "Details Driver",
        },
      },
      {
        path: "DriverDetails/Trips/:id",
        component: DriverTripsViewComponent,
        data: {
          title: "Trips Driver",
        },
      },
      {
        path: "DriverDetails/Score/:id",
        component: DriverScoreDetailsViewComponent,
        data: {
          title: "Score Driver",
        },
      },
      {
        path: "DriverDetails/External/:id",
        component: DriverWaslViewComponent,
        data: {
          title: "Wasl Driver",
        },
      },
      {
        path: "DriverDetails/Log/:id",
        component: DriverLogViewComponent,
        data: {
          title: "Log Driver",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "TrailerDetails/Index/:id",
        component: TrailerDetailsViewComponent,
        data: {
          title: "Details Trailer",
        },
      },
      {
        path: "TrailerDetails/Log/:id",
        component: TrailerLogViewComponent,
        data: {
          title: "Log Trailer",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "ProjectDetails/Index/:id",
        component: ProjectDetailsViewComponent,
        data: {
          title: "Details Project",
        },
      },
      {
        path: "ProjectDetails/Log/:id",
        component: ProjectLogViewComponent,
        data: {
          title: "Log Projects",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "WarehouseZoneDetails/Index/:id",
        component: WarehouseZoneDetailsViewComponent,
        data: {
          title: "Details Warehouse Zone",
        },
      },
      {
        path: "WarehouseZoneDetails/Add/:id",
        component: WarehouseZoneDetailsViewComponent,
        data: {
          title: "Add Warehouse Zone",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "WarehouseDetails/Index/:id",
        component: WarehousesDetailsViewComponent,
        data: {
          title: "Details Warehouse",
        },
      },
      {
        path: "WarehouseDetails/Charts/:id",
        component: WarehouseChartsViewComponent,
        data: {
          title: "Charts Warehouse",
        },
      },
      {
        path: "WarehouseDetails/Zones/:id",
        component: WarehouseZonesViewComponent,
        data: {
          title: "Zones Warehouse",
        },
      },
      {
        path: "WarehouseDetails/Sensors/:id",
        component: WarehouseSensorsViewComponent,
        data: {
          title: "Sensors Warehouse",
        },
      },
      {
        path: "WarehouseDetails/Messages/:id",
        component: WarehouseMessageViewComponent,
        data: {
          title: "Messages Warehouse",
        },
      },
      {
        path: "WarehouseDetails/Events/:id",
        component: WarehouseEventsViewComponent,
        data: {
          title: "Events Warehouse",
        },
      },
      {
        path: "WarehouseDetails/External/:id",
        component: WarehouseWaslViewComponent,
        data: {
          title: "External Warehouse",
        },
      },
      {
        path: "WarehouseDetails/Log/:id",
        component: WarehousesLogViewComponent,
        data: {
          title: "Log Warehouse",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "DriverGroupDetails/Index/:id",
        component: DriverGroupDetailsViewComponent,
        data: {
          title: "Details DriverGroup",
        },
      },
      {
        path: "DriverGroupDetails/Log/:id",
        component: DriverGroupLogViewComponent,
        data: {
          title: "Log DriverGroup",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "GeofenceDetails/Index/:id",
        component: GeofenceDetailsViewComponent,
        data: {
          title: "Details Geofence",
        },
      },
      {
        path: "GeofenceDetails/GeofenceStates/:id",
        component: GeofenceStatesViewComponent,
        data: {
          title: "States Geofence",
        },
      },
      {
        path: "GeofenceDetails/GeofenceEvents/:id",
        component: GeofenceEventsViewComponent,
        data: {
          title: "Events Events",
        },
      },
      {
        path: "GeofenceDetails/GeofenceEpisodes/:id",
        component: GeofenceEpisodesViewComponent,
        data: {
          title: "Episodes Geofence",
        },
      },
      {
        path: "GeofenceDetails/Log/:id",
        component: GeofenceLogViewComponent,
        data: {
          title: "Log Geofence",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "GeofenceGroupDetails/Index/:id",
        component: GeofenceGroupDetailsViewComponent,
        data: {
          title: "Details GeofenceGroup",
        },
      },
      {
        path: "GeofenceGroupDetails/Log/:id",
        component: GeofenceGroupLogViewComponent,
        data: {
          title: "Log GeofenceGroup",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Devices/DeviceTypeDetails/Index/:id",
        component: DeviceTypeDetailsViewComponent,
        data: {
          title: "Details DeviceType",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "System/BatchDetails/Index/:id",
        component: BatchDetailsViewComponent,
        data: {
          title: "Details Batch",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "AccountDetails/Index/:id",
        component: AccountDetailsViewComponent,
        data: {
          title: "Details Account",
        },
      },
      {
        path: "AccountDetails/External/:id",
        component: AccountWaslViewComponent,
        data: {
          title: "Wasl Account",
        },
      },
      {
        path: "AccountDetails/Shared/:id",
        component: AccountSharingComponent,
        data: {
          title: "Shared Account",
        },
      },
      {
        path: "AccountDetails/Matrix/:id",
        component: AccountMatrixComponent,
        data: {
          title: "Account Matrix",
        },
      },
      {
        path: "AccountDetails/DriverScore/:id",
        component: AccountDriverScoreViewComponent,
        data: {
          title: "DriverScore Account",
        },
      },
      {
        path: "AccountDetails/AssetScore/:id",
        component: AccountAssetScoreViewComponent,
        data: {
          title: "AssetScore Account",
        },
      },
      {
        path: "AccountDetails/Utilization/:id",
        component: AccountUtilizationViewComponent,
        data: {
          title: "Utilization Account",
        },
      },
      {
        path: "AccountDetails/Trends/:id",
        component: AccountTrendsViewComponent,
        data: {
          title: "Utilization Trends",
        },
      },
      {
        path: "AccountDetails/Settings/:id",
        component: AccountSettingsViewComponent,
        data: {
          title: "Settings Account",
        },
      },
      {
        path: "AccountDetails/Notifications/:id",
        component: AccountNotificationsViewComponent,
        data: {
          title: "Notifications Account",
        },
      },
      {
        path: "AccountDetails/Log/:id",
        component: AccountLogViewComponent,
        data: {
          title: "Log Account",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "ResellerDetails/Index/:id",
        component: ResellerDetailsViewComponent,
        data: {
          title: "Details Reseller",
        },
      },
      {
        path: "ResellerDetails/Log/:id",
        component: ResellerLogViewComponent,
        data: {
          title: "Log Reseller",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Notifications",
        component: NotificationsViewComponent,
        data: {
          title: "Notifications",
        },
      },
      {
        path: "Notifications/Overview",
        component: NotificationsViewComponent,
        data: {
          title: "Notifications",
        },
      },
      {
        path: "Notifications/Live",
        component: NotificationsLiveViewComponent,
        data: {
          title: "Notifications",
        },
      },
      {
        path: "Notifications/Settings",
        component: NotificationsSettingsViewComponent,
        data: {
          title: "Notifications",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Notifications/TriggerDetails/Index/:id",
        component: TriggerDashboardViewComponent,
        data: {
          title: "Dashboard Trigger",
        },
      },
      {
        path: "Notifications/TriggerDetails/Details/:id",
        component: TriggerDetailsViewComponent,
        data: {
          title: "Details Trigger",
        },
      },
      {
        path: "Notifications/TriggerDetails/Notifications/:id",
        component: TriggerNotificationsViewComponent,
        data: {
          title: "Notifications Trigger",
        },
      },
      {
        path: "Notifications/TriggerDetails/Episodes/:id",
        component: TriggerEpisodesViewComponent,
        data: {
          title: "Episodes Trigger",
        },
      },
      {
        path: "Notifications/TriggerDetails/Log/:id",
        component: TriggerLogViewComponentLogViewComponent,
        data: {
          title: "Episodes Log",
        },
      },
      {
        path: "Notifications/Triggers/Add",
        component: TriggerAddViewComponent,
        data: {
          title: "Add Trigger",
        },
      },
      {
        path: "Notifications/UserContacts",
        component: UserContactsViewComponent,
        data: {
          title: "User contacts",
        },
      },
      {
        path: "Notifications/UserContacts/Overview",
        component: UserContactsViewComponent,
        data: {
          title: "User contacts",
        },
      },
      {
        path: "Notifications/UserContactGroupDetails/Add",
        component: UserContactGroupAddViewComponent,
        data: {
          title: "User contactGroup  add",
        },
      },
      {
        path: "Notifications/UserContactDetails/Add",
        component: UserContactAddViewComponent,
        data: {
          title: "User contact add",
        },
      },
      {
        path: "Notifications/UserContactGroups",
        component: UserContactGroupsViewComponent,
        data: {
          title: "User contactGroups",
        },
      },
      {
        path: "Notifications/UserContactGroups/Overview",
        component: UserContactGroupsViewComponent,
        data: {
          title: "User contactGroups",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "FuelDetails/Index/:id",
        component: FuelDetailsViewComponent,
        data: {
          title: "Details Fuel",
        },
      },
      {
        path: "Fuel/Add",
        component: FuelAddViewComponent,
        data: {
          title: "Add Fuel",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Users/Add",
        component: UserAddViewComponent,
        data: {
          title: "Add user",
        },
      },
      {
        path: "Users/UserRoles/Overview",
        component: UserRolesViewComponent,
        data: {
          title: "User roles",
        },
      },
      {
        path: "Users/UserRoleDetails/Add",
        component: UserRoleAddViewComponent,
        data: {
          title: "User role add",
        },
      },
      {
        path: "UserDetails/Index/:id",
        component: UserDetailsViewComponent,
        data: {
          title: "Details User",
        },
      },
      {
        path: "UserDetails/Notifications/:id",
        component: UserNotificationsViewComponent,
        data: {
          title: "Notifications User",
        },
      },
      {
        path: "UserDetails/Log/:id",
        component: UserLogViewComponent,
        data: {
          title: "Log User",
        },
      },
    ],
  },
  {
    path: "",
    component: BlankLayoutComponent,
    children: [
      {
        path: "Login",
        component: LoginComponent,
        data: {
          title: "Login",
        },
      },
      {
        path: "Sharing/:token",
        component: RedirectComponent,
        data: {
          title: "Sharing",
        },
      },
      {
        path: "Register",
        component: RegisterComponent,
        data: {
          title: "Register",
        },
      },
      {
        path: "ForgotPassword",
        component: ForgotComponent,
        data: {
          title: "ForgotPassword",
        },
      },
      {
        path: "Unsubscribe/:id",
        component: UnsubscribeComponent,
        data: {
          title: "Unsubscribe",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Error404",
        component: Error404Component,
        data: {
          title: "Error404",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "About",
        component: AboutViewComponent,
        data: {
          title: "About",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "ChangeLog",
        component: ChangelogViewComponent,
        data: {
          title: "ChangeLog",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Help",
        component: HelpViewComponent,
        data: {
          title: "Help",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Trigger",
        component: PlaceholderViewComponent,
        data: {
          title: "Trigger",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Fuel",
        component: PlaceholderViewComponent,
        data: {
          title: "Fuel",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "Maintenance",
        component: PlaceholderViewComponent,
        data: {
          title: "Maintenance",
        },
      },
    ],
  },
  {
    path: "",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "ImportExport",
        component: PlaceholderViewComponent,
        data: {
          title: "ImportExport",
        },
      },
    ],
  },
  // Handle all other routes
  {
    path: "**",
    redirectTo: "Error404",
    data: {
      title: "Error 404",
    },
  },
];
