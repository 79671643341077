import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { throwError } from "rxjs/internal/observable/throwError";
import { WorkOrder, WorkOrderItem } from "app/models/workOrder.model";
import { CommandResult } from "app/models/commandresult.model";

@Injectable()
export class WorkOrderService {
  url = "";

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    this.url = this.authenticationService.getWebserviceURL("WorkOrder");
  }

  getWorkOrders(): Observable<WorkOrder[]> {
    return this.http
      .get<WorkOrder[]>(this.url, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  getWorkOrderById(workOrderId: number): Observable<WorkOrder> {
    return this.http
      .get<WorkOrder>(this.url + workOrderId, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  createWorkOrder(request: WorkOrder): Observable<CommandResult> {
    return this.http
      .post<CommandResult>(this.url, request, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateWorkOrder(workOrderId: number, request: WorkOrder): Observable<CommandResult> {
    return this.http
      .put<CommandResult>(this.url + workOrderId, request, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteWorkOrder(workOrderId: number, assetId: number): Observable<CommandResult> {
    return this.http
      .delete<CommandResult>(this.url + workOrderId + "/asset/" + assetId, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(error);
  }
}
