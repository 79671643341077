import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { throwError } from "rxjs/internal/observable/throwError";
import { ServiceInterval, ServiceItem } from "app/models/serviceItem.model";
import { CommandResult } from "app/models/commandresult.model";

@Injectable()
export class ServiceItemService {
  url = "";

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    this.url = this.authenticationService.getWebserviceURL("ServiceItem");
  }

  getServiceItems(): Observable<ServiceItem[]> {
    return this.http
      .get<ServiceItem[]>(this.url, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  getServiceItemById(serviceItemId: number): Observable<ServiceItem> {
    return this.http
      .get<ServiceItem>(this.url + serviceItemId, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  createServiceItem(request: ServiceItem): Observable<CommandResult> {
    return this.http
      .post<CommandResult>(this.url, request, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateServiceItem(serviceItemId: number, request: ServiceItem): Observable<CommandResult> {
    return this.http
      .put<CommandResult>(this.url + serviceItemId, request, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteServiceItem(serviceItemId: number, accountId: number): Observable<CommandResult> {
    return this.http
      .delete<CommandResult>(this.url + serviceItemId + "/account/" + accountId, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(error);
  }
}
