import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule } from "@angular/core";
// Forms
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
// Leaflet
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { TranslateModule } from "@ngx-translate/core";
// Calendar
import { DataTablesModule } from "angular-datatables";
// Modules
import { MomentModule } from "ngx-moment";
// Pipes
import { FilterRelevantPipe } from "app/common/relevant.pipe";
import { TextFilterPipe } from "app/common/textFilter.pipe";
import { DataTables360Module } from "app/modules/360grid";
import { DeviceSettingsViewComponent } from "app/views/devices/deviceSettings.component";
import { DevicesMapViewComponent } from "app/views/devices/map.component";
import { DeviceTripsViewComponent } from "app/views/devices/trips.component";
// NGX-Bootstrap
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { ModalModule } from "ngx-bootstrap/modal";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { SortableModule } from "ngx-bootstrap/sortable";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TooltipModule } from "ngx-bootstrap/tooltip";
// Tags (chips)
import { TagInputModule } from "ngx-chips";
// Color picker
import { ColorPickerModule } from "ngx-color-picker";
import { CountoModule } from "../modules/counto/counto.module";
// Modules
import { ICheckModule } from "../modules/icheck/icheck.module";
import { PrettyJsonModule } from "../modules/prettyjson/prettyjson.module";
import { TimezoneSelectorModule } from "../modules/timezoneselector/timezoneselector.module";
import { WizardModule } from "../modules/wizard/wizard.module";
import { AccountDetailsViewComponent } from "./accounts/accountDetails.component";
import { AccountLogViewComponent } from "./accounts/accountLog.component";
import { AboutViewComponent } from "./appviews/about.component";
import { DashboardComponent } from "./dashboards/dashboard.component";
// Error
import { Error404Component } from "./appviews/error404.component";
import { ForgotComponent } from "./appviews/forgotPassword.component";
import { HelpViewComponent } from "./appviews/help.component";
import { LoginComponent } from "./appviews/login.component";
import { RegisterComponent } from "./appviews/register.component";
// Search
import { SearchViewComponent } from "./appviews/search.component";
// Authentication callback components
import { TagListViewComponent } from "./appviews/taglist.component";
import { TagsViewComponent } from "./appviews/tags.component";
import { UnsubscribeComponent } from "./appviews/unsubscribe.component";
import { DeviceAddViewComponent } from "./devices/add.component";
import { DeviceDetailsViewComponent } from "./devices/deviceDetails.component";
import { DeviceLogViewComponent } from "./devices/deviceLog.component";
// Device
import { DevicesViewComponent } from "./devices/devices.component";
import { DeviceSensorSettingsViewComponent } from "./devices/sensorSettings.component";
import { DeviceTypeDetailsViewComponent } from "./devices/devicetypes/deviceTypeDetails.component";

// Device types
import { DevicesTypeViewComponent } from "./devices/devicetypes/deviceTypes.component";
import { DriversAddViewComponent } from "./drivers/add.component";
import { DriverDetailsViewComponent } from "./drivers/driverDetails.component";
import { DriverLogViewComponent } from "./drivers/driverLog.component";
import { DriversViewComponent } from "./drivers/drivers.component";

// Platform
import { ReportingCreateViewComponent } from "./reporting/reportingCreate.component";

import { SettingsGeneralComponent } from "./settings/general.component";
import { LanguageSettingsComponent } from "./settings/language.component";

// Settings
import { SettingsComponent } from "./settings/settings.component";
import { ThemeSettingsComponent } from "./settings/theme.component";

import { UsercontrolsModule } from "./shared/usercontrols.module";
import { UserAddViewComponent } from "./users/userAdd.component";
import { UserDetailsViewComponent } from "./users/userDetails.component";
import { UserLogViewComponent } from "./users/userLog.component";
// Users
import { UsersViewComponent } from "./users/users.component";
import { DeviceMessageViewComponent } from "./devices/messages.component";

// Geofences
import { GeofencesViewComponent } from "./geofences/geofences.component";
import { GeofencesAddViewComponent } from "./geofences/add.component";
import { GeofenceDetailsViewComponent } from "./geofences/geofenceDetails.component";
import { GeofenceLogViewComponent } from "./geofences/geofenceLog.component";
import { AccountsAddViewComponent } from "./accounts/add.component";
import { AccountsViewComponent } from "./accounts/accounts.component";

import { DriverGroupViewComponent } from "./drivers/groups.component";
import { DriverGroupAddViewComponent } from "./drivers/groupAdd.component";
import { DriverGroupDetailsViewComponent } from "./drivers/groupDetails.component";
import { GeofenceGroupAddViewComponent } from "./geofences/groupAdd.component";
import { GeofenceGroupViewComponent } from "./geofences/groups.component";
import { GeofenceGroupDetailsViewComponent } from "./geofences/groupDetails.component";
import { AssetGroupViewComponent } from "./assets/groups.component";
import { AssetGroupAddViewComponent } from "./assets/groupAdd.component";
import { AssetGroupDetailsViewComponent } from "./assets/groupDetails.component";
import { PlaceholderViewComponent } from "./appviews/placeHolder.component";
import { BatchViewComponent } from "./system/batch/batch.component";
import { BatchDetailsViewComponent } from "./system/batch/batchDetails.component";

import { NgxPaginationModule } from "ngx-pagination";
import { DeviceImmobilizationViewComponent } from "./devices/immobilization.component";
import { DeviceNotificationsViewComponent } from "./devices/notifications.component";
import { DeviceEpisodesViewComponent } from "./devices/episodes.component";
import { DeviceChangesViewComponent } from "./devices/changes.component";
import { DeviceRawDataViewComponent } from "./devices/rawData.component";
import { UserNotificationsViewComponent } from "./users/notifications.component";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { ResellersViewComponent } from "./resellers/resellers.component";
import { ResellerDetailsViewComponent } from "./resellers/resellerDetails.component";
import { ResellersAddViewComponent } from "./resellers/add.component";
import { TriggersViewComponent } from "./notifications/triggers/triggers.component";
import { TriggerDetailsViewComponent } from "./notifications/triggers/triggerDetails.component";
import { ArchiveRetentionRuleViewComponent } from "./settings/archiveretentionrules/archiveretentionrule.component";
// import { ArchiveRetentionRuleDetailsViewComponent } from './archiveretentionrules/archiveretentionruleDetails.component';
import { FuelViewComponent } from "./fuel/fuel.component";
import { FuelDetailsViewComponent } from "./fuel/fuelDetails.component";
import { FuelThresholdsViewComponent } from "./fuel/fuelThresholds.component";
import { MaintenanceDetailsViewComponent } from "./maintenance/maintenanceDetails.component";
import { MaintenanceViewComponent } from "./maintenance/maintenance.component";
import { MaintenanceAddViewComponent } from "./maintenance/add.component";
import { MaintenanceTypeLogViewComponent } from "./maintenance/maintenanceTypes/maintenanceTypeLog.component";
import { MaintenanceTypeAddViewComponent } from "./maintenance/maintenanceTypes/addType.component";
import { DeviceMaintenanceViewComponent } from "./devices/maintenance.component";
import { DeviceMaintenanceV2HistoryViewComponent } from "./devices/maintenanceV2History.component";
import { DeviceMaintenanceV2ViewComponent } from "./devices/maintenanceV2.component";
import { DeviceDriversViewComponent } from "./devices/drivers.component";
import { DeviceOdoViewComponent } from "./devices/odo.component";
import { DeviceEngineHourViewComponent } from "./devices/enginehour.component";
import { AngularDualListBoxModule } from "app/modules/dual-listbox/angular-dual-listbox.module";
import { DeviceFuelViewComponent } from "./devices/fuel.component";
import { DeviceGeofenceStatesViewComponent } from "./devices/geofenceStates.component";
import { GeofenceStatesViewComponent } from "./geofences/geofenceStates.component";
import { DeviceAssetsViewComponent } from "./devices/assets.component";
import { MaintenanceLogViewComponent } from "./maintenance/maintenanceLog.component";
import { MaintenanceDueViewComponent } from "./maintenance/maintenanceDue.component";

import { MaintenanceTypeDetailsViewComponent } from "./maintenance/maintenanceTypes/typeDetails.component";
import { MaintenanceTypesViewComponent } from "./maintenance/maintenanceTypes/types.component";
import { AssetTypeDetailsViewComponent } from "./assets/assetTypes/typeDetails.component";
import { AssetTypesViewComponent } from "./assets/assetTypes/types.component";
import { AssetTypeAddViewComponent } from "./assets/assetTypes/addType.component";
import { CustomCommandViewComponent } from "./devices/customCommands/customCommands.component";
import { CustomCommandAddViewComponent } from "./devices/customCommands/addCommand.component";
import { UploaderModule } from "app/modules/genericUploader/uploader.module";
// import { ArchiveRetentionRuleAddViewComponent } from './archiveretentionrule/add.component';
import { FuelAddViewComponent } from "./fuel/add.component";

import { UserContactDetailsViewComponent } from "./notifications/userContacts/userContactDetails.component";
import { UserContactAddViewComponent } from "./notifications/userContacts/add.component";

import { UserContactGroupAddViewComponent } from "./notifications/userContactGroups/add.component";
import { UserContactGroupDetailsViewComponent } from "./notifications/userContactGroups/userContactGroupDetails.component";
import { UserContactGroupsViewComponent } from "./notifications/userContactGroups/userContactGroups.component";
import { UserContactsViewComponent } from "./notifications/userContacts/userContacts.component";
import { AuditLogComponent } from "./system/auditlog/auditLog.component";
import { AuditLogDetailsViewComponent } from "./system/auditlog/auditLogDetails.component";
import { ErrorLogDetailsViewComponent } from "./system/errorlog/errorLogDetails.component";
import { ActiveUsersComponent } from "./system/activeUsers/activeUsers.component";
import { FuelMapViewComponent } from "./fuel/map.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { ChangelogViewComponent } from "./appviews/changelog.component";
import { MarkdownModule } from "ngx-markdown";
import { DevicesStateViewComponent } from "./devices/state.component";
import { DevicesExternalViewComponent } from "./devices/external.component";
import { TriggerAddViewComponent } from "./notifications/triggers/add.component";
import { UserRolesViewComponent } from "./users/userRoles/userRoles.component";
import { UserRoleAddViewComponent } from "./users/userRoles/add.components";
import { ResellerLogViewComponent } from "./resellers/resellerLog.component";
import { DeviceTrailersViewComponent } from "./devices/trailers.component";
import { TrailersAddViewComponent } from "./trailers/add.component";
import { TrailersViewComponent } from "./trailers/trailers.component";
import { TrailerDetailsViewComponent } from "./trailers/trailerDetails.component";
import { TrailerLogViewComponent } from "./trailers/trailerLog.component";
import { UserRoleDetailsViewComponent } from "./users/userRoles/UserRoleDetails.component";
import { AccountSharingComponent } from "./accounts/sharing.component";
import { AccountMatrixComponent } from "./accounts/matrix.component";
import { ImportViewComponent } from "./exportImport/Import.component";
import { WarehousesViewComponent } from "./warehouses/warehouses.component";
import { WarehousesAddViewComponent } from "./warehouses/add.component";
import { WarehousesLogViewComponent } from "./warehouses/warehouseLog.component";
import { WarehousesDetailsViewComponent } from "./warehouses/warehouseDetails.component";
import { WarehouseChartsViewComponent } from "./warehouses/charts.component";
import { WarehouseMessageViewComponent } from "./warehouses/messages.component";
import { WarehouseZonesViewComponent } from "./warehouses/zones.component";
import { WarehouseZoneDetailsViewComponent } from "./warehouses/warehouseZoneDetails.component";
import { WarehouseWaslViewComponent } from "./warehouses/wasl.component";
import { WarehouseEventsViewComponent } from "./warehouses/events.component";
import { WarehouseSensorsViewComponent } from "./warehouses/sensors.component";
import { ngxLoadingAnimationTypes } from "app/modules/loading/ngx-loading-config";
import { NgxLoadingModule } from "app/modules/loading/ngx-loading.module";
import { CopyInputBoxModule } from "app/modules/copyInputBox/copyInputBox.module";
import { DeviceNavigationComponent } from "./shared/navigation/deviceNavigation.component";
import { ExportViewComponent } from "./exportImport/export.component";
import { ReportDisplayDetailsComponent } from "./reporting/reportDisplay.component";
import { ReportingViewComponent } from "./reporting/executions.component";
import { ReportingDetailsViewComponent } from "./reporting/reportingDetails.component";
import { CustomInputModule } from "app/modules/customInputs/customInput.module";
import { NotificationLogDetailsViewComponent } from "./notifications/notificationLog/notificationLogDetails.component";
import { NotificationLogComponent } from "./notifications/notificationLog/notificationLog.component";
import { NotificationChartViewComponent } from "./notifications/notificationLog/chart.component";
import { DevicesCardsViewComponent } from "./devices/cards.component";
import { DriverCardsViewComponent } from "./drivers/cards.component";
import { DriverTripsViewComponent } from "./drivers/trips.component";
import { DriverScoreDetailsViewComponent } from "./drivers/scoreDetails.component";
import { DeviceProjectsViewComponent } from "./devices/projects.component";
import { ProjectsAddViewComponent } from "./projects/add.component";
import { ProjectDetailsViewComponent } from "./projects/projectDetails.component";
import { ProjectLogViewComponent } from "./projects/projectLog.component";
import { ProjectsViewComponent } from "./projects/projects.component";
import { DriversExternalViewComponent } from "./drivers/external.component";

import { TriggerDashboardViewComponent } from "./notifications/triggers/triggerDashboard.component";
import { TriggerEpisodesViewComponent } from "./notifications/triggers/episodes.component";
import { TriggerSchedulesViewComponent } from "./notifications/triggerSchedules/triggerSchedules.component";
import { TriggerScheduleAddViewComponent } from "./notifications/triggerSchedules/addTriggerSchedule.component";
import { TriggerScheduleDetailsViewComponent } from "./notifications/triggerSchedules/triggerScheduleDetails.component";
import { DevicesUtilizationViewComponent } from "./devices/utilization.component";
import { HomeLiveComponent } from "./appviews/homeLive.component";
import { ServiceAnnouncementAddViewComponent } from "./settings/serviceAnnouncements/add.component";
import { ServiceAnnouncementsDetailsViewComponent } from "./settings/serviceAnnouncements/serviceAnnouncementDetails.component";
import { ServiceAnnouncementsViewComponent } from "./settings/serviceAnnouncements/serviceAnnouncements.component";
import { ClientAnnouncementAddViewComponent } from "./settings/clientAnnouncements/add.component";
import { ClientAnnouncementsDetailsViewComponent } from "./settings/clientAnnouncements/clientAnnouncementDetails.component";
import { ClientAnnouncementsViewComponent } from "./settings/clientAnnouncements/clientAnnouncements.component";
import { DeviceUtilizationDetailsViewComponent } from "./devices/utilizationDetails.component";
import { AccountSettingsViewComponent } from "./accounts/settings.component";
import { SensorTemplateAddViewComponent } from "./devices/sensorTemplates/addSensorTemplate.component";
import { SensorTemplateDetailsViewComponent } from "./devices/sensorTemplates/sensorTemplateDetails.component";
import { SensorTemplatesViewComponent } from "./devices/sensorTemplates/sensorTemplates.component";
import { SettingStateViewerComponent } from "./system/stateViewer/stateViewer.component";
// import { BatchDeleteComponent } from './shared/usercontrols/batch/controls/delete.component';
// Json viewer
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { DeviceSensorsHistoryViewComponent } from "./devices/sensorsHistory.component";
import { AccountTrendsViewComponent } from "./dashboards/trends.component";
import { ByPassSafeHtmlPipe } from "app/common/bypassSaveHtml.pipe";
import { DeviceRoutingConfigurationComponent } from "./devices/deviceRoutingConfiguration.component";
import { DeviceEpisodeDetailsViewComponent } from "./devices/episodeDetails.component";
import { TriggerLogViewComponentLogViewComponent } from "./notifications/triggers/triggerLog.component";
import { DeviceDetailsNavigationComponent } from "./shared/navigation/deviceDetailsNavigation.component";
import { DashboardNavigationComponent } from "./shared/navigation/dashboardNavigation.component";
import { ReportingNavigationComponent } from "./shared/navigation/reportingNavigation.component";
import { AccountNavigationComponent } from "./shared/navigation/accountNavigation.component";
import { AccountDetailsNavigationComponent } from "./shared/navigation/accountDetailsNavigation.component";
import { AccountWaslViewComponent } from "./accounts/wasl.component";
import { DeviceWaslViewComponent } from "./devices/wasl.component";
import { DriverWaslViewComponent } from "./drivers/wasl.component";
import { AccountUtilizationViewComponent } from "./dashboards/utilization.component";
import { IssuesViewComponent } from "./dashboards/issues.component";
import { TrailerNavigationComponent } from "./shared/navigation/trailerNavigation.component";
import { DriverNavigationComponent } from "./shared/navigation/driverNavigation.component";
import { UserNavigationComponent } from "./shared/navigation/userNavigation.component";
import { GeofenceNavigationComponent } from "./shared/navigation/geofenceNavigation.component";
import { ProjectNavigationComponent } from "./shared/navigation/projectNavigation.component";
import { ResellerNavigationComponent } from "./shared/navigation/resellerNavigation.component";
import { UserDetailsNavigationComponent } from "./shared/navigation/userDetailsNavigation.component";
import { GeofenceDetailsNavigationComponent } from "./shared/navigation/geofenceDetailsNavigation.component";
import { DriverDetailsNavigationComponent } from "./shared/navigation/driverDetailsNavigation.component";
import { SystemNavigationComponent } from "./shared/navigation/systemNavigation.component";
import { FuelNavigationComponent } from "./shared/navigation/fuelNavigation.component";
import { MaintenanceNavigationComponent } from "./shared/navigation/maintenanceNavigation.component";
import { SettingsNavigationComponent } from "./shared/navigation/settingsNavigation.component";
import { ProjectDetailsNavigationComponent } from "./shared/navigation/projectDetailsNavigation.component";
import { ResellerDetailsNavigationComponent } from "./shared/navigation/resellerDetailsNavigation.component";
import { TrailerDetailsNavigationComponent } from "./shared/navigation/trailerDetailsNavigation.component";
import { WarehouseNavigationComponent } from "./shared/navigation/warehouseNavigation.component";
import { WarehouseDetailsNavigationComponent } from "./shared/navigation/warehouseDetailsNavigation.component";
import { ProfileComponent } from "./appviews/profile.component";
import { SharingComponent } from "./appviews/sharing.component";
import { TokenComponent } from "./appviews/tokens.component";
import { TriggerScheduleDetailsNavigationComponent } from "./shared/navigation/triggerScheduleDetailsNavigation.component";
import { SystemGeneralComponent } from "./system/general.component";
import { WebhookEndpointsViewComponent } from "./system/webhookEndpoints/webhookEndpoints.component";
import { WebhookEndpointAddViewComponent } from "./system/webhookEndpoints/addWebhookEndpoint.component";
import { WebhookEndpointDetailsViewComponent } from "./system/webhookEndpoints/webhookEndpointDetails.component";
import { DriverAssignmentsViewComponent } from "./drivers/assignments.component";
import { DeviceAssignmentsViewComponent } from "./devices/assignments.component";
import { ReportingSubscriptionsViewComponent } from "./reporting/subscriptions.component";
import { CronEditorModule } from "app/modules/cron-editor/cron-editor.module";
import { SubscriptionDetailsViewComponent } from "./reporting/subscriptionDetails.component";
import { SubscriptionLogViewComponent } from "./reporting/subscriptionLog.component";
import { ReportingTemplatesViewComponent } from "./reporting/templates.component";
import { HighchartsChartModule } from "highcharts-angular";
import { IssuesLatencyViewComponent } from "./dashboards/latency.component";
import { DeviceSettingChangesViewComponent } from "./devices/changesOverview.component";
import { CarSharingDamagesViewComponent } from "./carsharing/damages.component";
import { CarSharingViewComponent } from "./carsharing/dashboard.component";

import { CarSharingNavigationComponent } from "./shared/navigation/carSharingNavigation.component";
import { CarSharingGaragesViewComponent } from "./carsharing/garages.component";
import { CarSharingGaragesDetailsViewComponent } from "./carsharing/garageDetails.component";
import { CarSharingGaragesAddViewComponent } from "./carsharing/garageAdd.component";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { EcoDrivingNavigationComponent } from "./shared/navigation/ecoDrivingNavigation.component";
import { AccountEquipmentViewComponent } from "./equipment/equipment.component";
import { EquipmentNavigationComponent } from "./shared/navigation/equipmentNavigation.component";
import { EquipmentDayViewViewComponent } from "./equipment/dayview.component";
import { DeviceDayViewViewComponent } from "./devices/dayview.component";
import { DriverDayViewViewComponent } from "./drivers/dayview.component";
import { DeviceRoutesViewComponent } from "./devices/routes.component";
import { GeofenceGroupLogViewComponent } from "./geofences/geofenceGroupLog.component";
import { DriverGroupLogViewComponent } from "./drivers/driverGroupLog.component";
import { AssetGroupLogViewComponent } from "./assets/assetGroupLog.component";
import { DriverTripsDayViewViewComponent } from "./drivers/dayviewTrips.component";
import { TriggerNotificationsViewComponent } from "./notifications/triggers/notifications.component";
import { AccountFuelViewComponent } from "./dashboards/fuel.component";
import { FuelDashboardViewComponent } from "./fuel/dashboard.component";
import { AccountAssetScoreViewComponent } from "./ecodriving/assetScore.component";
import { AccountDriverScoreViewComponent } from "./ecodriving/driverScore.component";
import { AccountDriverScoreSettingsViewComponent } from "./ecodriving/driverScoreSettings.component";
import { FleetOverviewComponent } from "./overview/overview.component";
import { NotificationsLiveViewComponent } from "./notifications/notificationsLive.component";
import { MyAccountComponent } from "./appviews/myAccount.component";
import { FleetOverviewNavigationComponent } from "./overview/overviewNavigation.component";
import { FleetOverviewSidebarComponent } from "./overview/sidebar.component";
import { FleetOverviewTripHistoryComponent } from "./overview/overviewTripHistory.component";
import { DevicesTripsOverViewComponent } from "./devices/tripsOverview.component";
import { HomeNavigationComponent } from "./shared/navigation/homeNavigation.component";
import { HomeHistoryComponent } from "./appviews/homeHistory.component";
import { HomeComponent } from "./appviews/home.component";
import { NotificationNavigationComponent } from "./shared/navigation/notificationsNavigation.component";
import { NotificationsViewComponent } from "./notifications/notifications.component";
import { NotificationsSettingsViewComponent } from "./notifications/notificationsSettings.component";
import { AccountNotificationsViewComponent } from "./accounts/notifications.component";
import { VideoModule } from "app/modules/video/vjs-player.module";
import { AccountCostsViewComponent } from "./dashboards/costs.component";
import { DeviceGeofenceEventsViewComponent } from "./devices/geofenceEvents.component";
import { DeviceGeofenceEpisodesViewComponent } from "./devices/geofenceEpisodes.component";
import { GeofenceEventsViewComponent } from "./geofences/geofenceEvents.component";
import { GeofenceEpisodesViewComponent } from "./geofences/geofenceEpisodes.component";
import { ReportingSharedDetailsViewComponent } from "./reporting/reportingSharedDetails.component";
import { DeviceShareDetailsViewComponent } from "./devices/deviceShareDetails.component";
import { DeviceSharedTripsViewComponent } from "./devices/sharedTrips.component";
import { DeviceSharedTripDetailsViewComponent } from "./devices/sharedTripDetails.component";
import { RedirectComponent } from "./appviews/redirect.component";
import { ReportingDashboardComponent } from "./reporting/dashboard.component";
import { SubscriptionEditViewComponent } from "./reporting/subscriptionEdit.component";
import { CustomCommandDetailsViewComponent } from "./devices/customCommands/commandDetails.component";
import { MaintenanceV2DashboardViewComponent } from "./maintenanceV2/dashboard.component";
import { MaintenanceV2NavigationComponent } from "./shared/navigation/maintenanceV2Navigation.component";
import { MaintenanceServiceItemViewComponent } from "./maintenanceV2/serviceItem/serviceItems.component";
import { MaintenanceServiceItemAddViewComponent } from "./maintenanceV2/serviceItem/addServiceItem.component";
import { MaintenanceServiceItemDetailsViewComponent } from "./maintenanceV2/serviceItem/serviceItemDetails.component";
import { MaintenanceWorkOrderViewComponent } from "./maintenanceV2/workorders/workOrders.component";
import { MaintenanceWorkOrderAddViewComponent } from "./maintenanceV2/workorders/addWorkOrder.component";
import { MaintenanceWorkOrderDetailsViewComponent } from "./maintenanceV2/workorders/workOrderDetails.component";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { ErrorLogComponent } from "./system/errorlog/errorlog.component";
import { FleetOverviewGeofenceHistoryComponent } from "./overview/overviewGeofenceHistory.component";
import { CockpitComponent } from "./cockpit/cockpit.component";
import { CockpitSidebarComponent } from "./cockpit/cockpitSidebar.component";
import { CockpitNavigationComponent } from "./cockpit/cockpitNavigation.component";
import { HierarchyGroupsViewComponent } from "./system/hierarchy/hierarchyGroups.component";
import { HierarchyViewComponent } from "./system/hierarchy/dashboard.component";
import { HierarchyGroupDetailsViewComponent } from "./system/hierarchy/hierarchyGroupDetails.component";
import { HierarchyGroupsAddViewComponent } from "./system/hierarchy/addhierarchyGroup.component";
import { HierarchyDisplayComponent } from "./system/hierarchy/hierarchyDisplay.component";
import { MaintenanceV2DueViewComponent } from "./maintenanceV2/maintenancedue/maintenanceDue.component";

@NgModule({
  declarations: [
    LoginComponent,
    RedirectComponent,
    RegisterComponent,
    FleetOverviewComponent,
    FleetOverviewNavigationComponent,
    FleetOverviewSidebarComponent,
    FleetOverviewTripHistoryComponent,
    FleetOverviewGeofenceHistoryComponent,
    CockpitComponent,
    CockpitSidebarComponent,
    CockpitNavigationComponent,
    ForgotComponent,
    DashboardComponent,
    HomeComponent,
    HomeLiveComponent,
    HomeHistoryComponent,
    UnsubscribeComponent,
    AssetGroupViewComponent,
    AssetGroupAddViewComponent,
    AssetGroupDetailsViewComponent,
    BatchViewComponent,
    BatchDetailsViewComponent,
    DeviceNavigationComponent,
    AccountNavigationComponent,
    ResellerNavigationComponent,
    SystemNavigationComponent,
    NotificationNavigationComponent,
    DashboardNavigationComponent,
    EquipmentNavigationComponent,
    EcoDrivingNavigationComponent,
    HomeNavigationComponent,
    CarSharingNavigationComponent,
    ReportingNavigationComponent,
    DeviceDetailsNavigationComponent,
    AccountDetailsNavigationComponent,
    UserDetailsNavigationComponent,
    GeofenceDetailsNavigationComponent,
    ProjectDetailsNavigationComponent,
    ResellerDetailsNavigationComponent,
    TrailerDetailsNavigationComponent,
    DriverDetailsNavigationComponent,
    TrailerNavigationComponent,
    ProjectNavigationComponent,
    WarehouseNavigationComponent,
    WarehouseDetailsNavigationComponent,
    DriverNavigationComponent,
    UserNavigationComponent,
    SettingsNavigationComponent,
    FuelNavigationComponent,
    MaintenanceNavigationComponent,
    MaintenanceV2NavigationComponent,
    GeofenceNavigationComponent,
    DevicesViewComponent,
    DeviceDetailsViewComponent,
    DeviceShareDetailsViewComponent,
    DeviceFuelViewComponent,
    DeviceSettingsViewComponent,
    DeviceTripsViewComponent,
    DeviceSharedTripsViewComponent,
    DeviceSharedTripDetailsViewComponent,
    DeviceRoutesViewComponent,
    DevicesMapViewComponent,
    DevicesStateViewComponent,
    DevicesCardsViewComponent,
    DeviceSettingChangesViewComponent,
    DevicesExternalViewComponent,
    DevicesUtilizationViewComponent,
    DeviceLogViewComponent,
    DeviceMessageViewComponent,
    DevicesTypeViewComponent,
    DeviceSensorSettingsViewComponent,
    DeviceSensorsHistoryViewComponent,
    DeviceDayViewViewComponent,
    DriverTripsDayViewViewComponent,
    DeviceChangesViewComponent,
    DeviceImmobilizationViewComponent,
    DeviceUtilizationDetailsViewComponent,
    DeviceAssetsViewComponent,
    DeviceRawDataViewComponent,
    DeviceNotificationsViewComponent,
    DeviceEpisodesViewComponent,
    DeviceEpisodeDetailsViewComponent,
    DeviceGeofenceStatesViewComponent,
    DeviceGeofenceEventsViewComponent,
    DeviceGeofenceEpisodesViewComponent,
    DeviceAddViewComponent,
    DeviceTypeDetailsViewComponent,
    DeviceDriversViewComponent,
    DeviceAssignmentsViewComponent,
    DevicesTripsOverViewComponent,
    DeviceMaintenanceViewComponent,
    DeviceMaintenanceV2HistoryViewComponent,
    DeviceMaintenanceV2ViewComponent,
    DeviceTrailersViewComponent,
    DeviceProjectsViewComponent,
    DeviceOdoViewComponent,
    DeviceEngineHourViewComponent,
    TrailersViewComponent,
    TrailersAddViewComponent,
    TrailerDetailsViewComponent,
    TrailerLogViewComponent,
    ProjectsViewComponent,
    ProjectsAddViewComponent,
    ProjectDetailsViewComponent,
    ProjectLogViewComponent,
    SubscriptionLogViewComponent,
    DriversViewComponent,
    DriverCardsViewComponent,
    DriverAssignmentsViewComponent,
    DriverDayViewViewComponent,
    DriversAddViewComponent,
    DriverDetailsViewComponent,
    DriverScoreDetailsViewComponent,
    DriverTripsViewComponent,
    DriverLogViewComponent,
    DriverGroupViewComponent,
    DriverGroupAddViewComponent,
    DriverGroupDetailsViewComponent,
    DriversExternalViewComponent,
    DriverGroupLogViewComponent,
    GeofencesViewComponent,
    FuelMapViewComponent,
    GeofencesAddViewComponent,
    GeofenceDetailsViewComponent,
    GeofenceStatesViewComponent,
    GeofenceEventsViewComponent,
    GeofenceEpisodesViewComponent,
    GeofenceLogViewComponent,
    GeofenceGroupViewComponent,
    GeofenceGroupAddViewComponent,
    GeofenceGroupDetailsViewComponent,
    GeofenceGroupLogViewComponent,
    Error404Component,
    SearchViewComponent,
    TagsViewComponent,
    IssuesViewComponent,
    CarSharingViewComponent,
    CarSharingDamagesViewComponent,
    CarSharingGaragesViewComponent,
    CarSharingGaragesAddViewComponent,
    CarSharingGaragesDetailsViewComponent,
    IssuesLatencyViewComponent,
    TagListViewComponent,
    ReportingCreateViewComponent,
    ReportDisplayDetailsComponent,
    ReportingViewComponent,
    ReportingDashboardComponent,
    ReportingSubscriptionsViewComponent,
    ReportingTemplatesViewComponent,
    ReportingDetailsViewComponent,
    ReportingSharedDetailsViewComponent,
    SubscriptionDetailsViewComponent,
    SubscriptionEditViewComponent,
    ExportViewComponent,
    ImportViewComponent,
    SettingsComponent,
    ThemeSettingsComponent,
    LanguageSettingsComponent,
    UsersViewComponent,
    UserRolesViewComponent,
    UserRoleAddViewComponent,
    UserRoleDetailsViewComponent,
    UserDetailsViewComponent,
    UserLogViewComponent,
    UserNotificationsViewComponent,
    AccountsViewComponent,
    AccountDetailsViewComponent,
    AccountsAddViewComponent,
    AccountNotificationsViewComponent,
    AccountDriverScoreViewComponent,
    AccountDriverScoreSettingsViewComponent,
    AccountAssetScoreViewComponent,
    AccountUtilizationViewComponent,
    AccountFuelViewComponent,
    AccountCostsViewComponent,
    AccountEquipmentViewComponent,
    EquipmentDayViewViewComponent,
    AccountTrendsViewComponent,
    AccountSettingsViewComponent,
    ResellersViewComponent,
    ResellerDetailsViewComponent,
    ResellersAddViewComponent,
    MaintenanceViewComponent,
    MaintenanceDueViewComponent,
    MaintenanceDetailsViewComponent,
    MaintenanceAddViewComponent,
    MaintenanceTypesViewComponent,
    MaintenanceTypeLogViewComponent,
    MaintenanceTypeAddViewComponent,
    MaintenanceTypeDetailsViewComponent,
    MaintenanceLogViewComponent,
    // Maintenance v2
    MaintenanceV2DashboardViewComponent,
    MaintenanceServiceItemViewComponent,
    MaintenanceServiceItemAddViewComponent,
    MaintenanceServiceItemDetailsViewComponent,
    MaintenanceWorkOrderViewComponent,
    MaintenanceWorkOrderAddViewComponent,
    MaintenanceWorkOrderDetailsViewComponent,
    MaintenanceV2DueViewComponent,
    // End Maintenance v2
    AssetTypesViewComponent,
    AssetTypeAddViewComponent,
    AssetTypeDetailsViewComponent,
    AssetGroupLogViewComponent,
    CustomCommandViewComponent,
    CustomCommandAddViewComponent,
    TriggersViewComponent,
    TriggerDetailsViewComponent,
    TriggerAddViewComponent,
    TriggerEpisodesViewComponent,
    TriggerSchedulesViewComponent,
    TriggerScheduleAddViewComponent,
    TriggerScheduleDetailsViewComponent,
    TriggerDashboardViewComponent,
    TriggerLogViewComponentLogViewComponent,
    TriggerNotificationsViewComponent,
    UserContactDetailsViewComponent,
    UserContactAddViewComponent,
    UserContactsViewComponent,
    UserContactGroupDetailsViewComponent,
    UserContactGroupAddViewComponent,
    UserContactGroupsViewComponent,
    ArchiveRetentionRuleViewComponent,
    WarehousesViewComponent,
    WarehousesAddViewComponent,
    WarehousesDetailsViewComponent,
    WarehouseZoneDetailsViewComponent,
    WarehousesLogViewComponent,
    WarehouseChartsViewComponent,
    WarehouseMessageViewComponent,
    WarehouseZonesViewComponent,
    WarehouseSensorsViewComponent,
    WarehouseWaslViewComponent,
    WarehouseEventsViewComponent,
    // ArchiveRetentionRuleDetailsViewComponent,
    // ArchiveRetentionRuleAddViewComponent,
    AccountWaslViewComponent,
    DriverWaslViewComponent,
    DeviceWaslViewComponent,
    FuelViewComponent,
    FuelDetailsViewComponent,
    FuelAddViewComponent,
    FuelThresholdsViewComponent,
    FuelDashboardViewComponent,
    AccountLogViewComponent,
    ResellerLogViewComponent,
    UserAddViewComponent,
    ActiveUsersComponent,
    ErrorLogComponent,
    ErrorLogDetailsViewComponent,
    AuditLogDetailsViewComponent,
    AuditLogComponent,
    NotificationLogComponent,
    NotificationLogDetailsViewComponent,
    NotificationChartViewComponent,
    ProfileComponent,
    MyAccountComponent,
    SettingsGeneralComponent,
    TokenComponent,
    SettingStateViewerComponent,
    DeviceRoutingConfigurationComponent,
    AccountSharingComponent,
    AccountMatrixComponent,
    SharingComponent,
    ServiceAnnouncementAddViewComponent,
    ServiceAnnouncementsDetailsViewComponent,
    ServiceAnnouncementsViewComponent,
    ClientAnnouncementAddViewComponent,
    ClientAnnouncementsDetailsViewComponent,
    ClientAnnouncementsViewComponent,
    SensorTemplateAddViewComponent,
    SensorTemplateDetailsViewComponent,
    SensorTemplatesViewComponent,
    CustomCommandDetailsViewComponent,
    AboutViewComponent,
    ChangelogViewComponent,
    HelpViewComponent,
    FilterRelevantPipe,
    TextFilterPipe,
    ByPassSafeHtmlPipe,
    PlaceholderViewComponent,
    TriggerScheduleDetailsNavigationComponent,
    SystemGeneralComponent,
    WebhookEndpointsViewComponent,
    WebhookEndpointAddViewComponent,
    WebhookEndpointDetailsViewComponent,
    NotificationsLiveViewComponent,
    NotificationsSettingsViewComponent,
    NotificationsViewComponent,
    HierarchyViewComponent,
    HierarchyDisplayComponent,
    HierarchyGroupsViewComponent,
    HierarchyGroupDetailsViewComponent,
    HierarchyGroupsAddViewComponent,
  ],
  imports: [
    VideoModule,
    CustomInputModule,
    CronEditorModule,
    MarkdownModule.forRoot(),
    NgSelectModule,
    NgOptionHighlightModule,
    BrowserModule,
    RouterModule,
    HighchartsChartModule,
    NgxPaginationModule,
    DataTablesModule,
    DataTables360Module,
    AngularDualListBoxModule,
    UploaderModule,
    MomentModule,
    NgxSliderModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CountoModule,
    TimezoneSelectorModule,
    TagInputModule,
    TypeaheadModule.forRoot(),
    ICheckModule.forRoot(),
    WizardModule,
    CopyInputBoxModule,
    SortableModule.forRoot(),
    CarouselModule.forRoot(),
    TabsModule.forRoot(),
    LeafletModule,
    NgxJsonViewerModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    UsercontrolsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: "rgba(255,255,255,0.7)",
      backdropBorderRadius: "4px",
      primaryColour: "#DC8139",
      secondaryColour: "#DC8139",
      tertiaryColour: "#DC8139",
    }),
    ColorPickerModule,
    TranslateModule,
    PrettyJsonModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
  exports: [DashboardComponent, LoginComponent, TranslateModule],
})
export class AppviewsModule {}
