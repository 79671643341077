<div class="col-lg-12">
    <div class="horizontalMenuWrapper">
        <ul>
            <li [ngClass]="{'active': activeRoute('Index')}">
                <a href="/#/DeviceDetails/Index/{{deviceId}}"
                    ><i class="fas fa-fw fa-microchip"></i>
                    <div>{{ 'general.dashboard' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['Assets_View']" [ngClass]="{'active': isAssets()}">
                <a href="/#/DeviceDetails/Assets/{{deviceId}}"
                    ><i class="fas fa-fw fa-car-bus"></i>
                    <div>{{ 'general.assets' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_Trips'] && !isAssetTracker"
                [ngClass]="{active: activeRoute('DeviceDetails/Trips')}"
            >
                <a [routerLink]="['/DeviceDetails/Trips/', deviceId]">
                    <i class="fas fa-fw fa-route"></i>
                    <div>{{ 'menu.trips' | translate }}</div>
                </a>
            </li>

            <li
                *ngIf="permissions['Maintenances_View'] && !hasFeature('enableBetaMaintenance')"
                [ngClass]="{active: activeRoute('DeviceDetails/Maintenance/')}"
            >
                <a [routerLink]="['/DeviceDetails/Maintenance/', deviceId]">
                    <i class="fas fa-fw fa-car-mechanic"></i>
                    <div>{{ 'general.maintenance' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_DeviceMaintenance'] && hasFeature('enableBetaMaintenance')"
                [ngClass]="{active: activeRoute('DeviceDetails/MaintenanceV2/Maintenance')}"
            >
                <a [routerLink]="['/DeviceDetails/MaintenanceV2/Maintenance', deviceId]">
                    <i class="fas fa-fw fa-car-mechanic"></i>
                    <div>V2</div>
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_DeviceFuel']"
                [ngClass]="{active: activeRoute('DeviceDetails/Fuel')}"
            >
                <a [routerLink]="['/DeviceDetails/Fuel/', deviceId]">
                    <i class="fas fa-fw fa-gas-pump"></i>
                    <div>{{ 'menu.fuel' | translate }}</div>
                </a>
            </li>
            <li *ngIf="hasSettingPermissions" [ngClass]="{'active': isSettings()}">
                <a (click)="navigateSettings()"
                    ><i class="fas fa-fw fa-cog"></i>
                    <div>{{ 'general.settings' | translate }}</div>
                </a>
            </li>
            <li *ngIf="hasDataPermissions" [ngClass]="{'active': isData()}">
                <a (click)="navigateData()"
                    ><i class="fas fa-fw fa-database"></i>
                    <div>{{ 'menu.data' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Log']" [ngClass]="{'active': activeRoute('Log')}">
                <a href="/#/DeviceDetails/Log/{{deviceId}}"
                    ><i class="fas fa-fw fa-file-alt"></i>
                    <div>{{ 'menu.log' | translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class="horizontalMenuWrapper carIconHolder">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>

<div class="col-lg-12" style="padding: 5px" *ngIf="isAssets()">
    <div class="horizontalMenuSubWrapper">
        <ul>
            <li *ngIf="permissions['Assets_View']" [ngClass]="{'active': activeRoute('Assets')}">
                <a href="/#/DeviceDetails/Assets/{{deviceId}}"
                    ><i class="fas fa-fw fa-car-bus"></i>
                    {{ 'general.assets' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['Drivers_View'] && !isAssetTracker" [ngClass]="{'active': activeRoute('Drivers')}">
                <a href="/#/DeviceDetails/Drivers/{{deviceId}}"
                    ><i class="fas fa-fw fa-id-card-alt"></i>
                    {{ 'general.drivers' | translate }}
                </a>
            </li>
            <li
                *ngIf="!hideTrailers && permissions['Trailers_View'] && !isAssetTracker"
                [ngClass]="{'active': activeRoute('Trailers')}"
            >
                <a href="/#/DeviceDetails/Trailers/{{deviceId}}"
                    ><i class="fas fa-fw fa-trailer"></i>
                    {{ 'general.trailers' | translate }}
                </a>
            </li>
            <li
                *ngIf="!hideProjects && permissions['Projects_View'] && !isAssetTracker"
                [ngClass]="{'active': activeRoute('Projects')}"
            >
                <a href="/#/DeviceDetails/Projects/{{deviceId}}"
                    ><i class="fas fa-fw fa-gavel"></i>
                    {{ 'general.projects' | translate }}
                </a>
            </li>
        </ul>
    </div>
</div>

<div class="col-lg-12" style="padding: 5px" *ngIf="isData()">
    <div class="horizontalMenuSubWrapper">
        <ul>
            <li
                *ngIf="permissions['FleetManagement_Messages']"
                [ngClass]="{active: activeRoute('DeviceDetails/Messages')}"
            >
                <a [routerLink]="['/DeviceDetails/Messages/', deviceId]">
                    <i class="fa fa-fw fa-comment-alt-dots"></i>
                    {{ 'general.messages' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_Notifications']"
                [ngClass]="{active: activeRoute('DeviceDetails/Notifications')}"
            >
                <a [routerLink]="['/DeviceDetails/Notifications/', deviceId]">
                    <i class="fas fa-fw fa-envelope-open-text"></i>
                    {{ 'general.notifications' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_Episodes']"
                [ngClass]="{active: activeRoute('DeviceDetails/Episodes')}"
            >
                <a [routerLink]="['/DeviceDetails/Episodes/', deviceId]">
                    <i class="fas fa-fw fa-traffic-light-stop"></i>
                    {{ 'general.episodes' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_GeofenceStates']"
                [ngClass]="{active: activeRoute('DeviceDetails/GeofenceStates')}"
            >
                <a [routerLink]="['/DeviceDetails/GeofenceStates/', deviceId]">
                    <i class="fa fa-fw fa-hexagon"></i>
                    {{ 'general.geofenceStates' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['Platform_IsReseller']"
                [ngClass]="{active: activeRoute('DeviceDetails/GeofenceEvents')}"
            >
                <a [routerLink]="['/DeviceDetails/GeofenceEvents/', deviceId]">
                    <i class="fa fa-fw fa-hexagon"></i>
                    {{ 'general.geofenceEvents' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['Platform_IsReseller']"
                [ngClass]="{active: activeRoute('DeviceDetails/GeofenceEpisodes')}"
            >
                <a [routerLink]="['/DeviceDetails/GeofenceEpisodes/', deviceId]">
                    <i class="fa fa-fw fa-hexagon"></i>
                    {{ 'general.geofenceEpisodes' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_Sensors']"
                [ngClass]="{active: activeRoute('DeviceDetails/SensorsHistory')}"
            >
                <a [routerLink]="['/DeviceDetails/SensorsHistory/', deviceId]">
                    <i class="fas fa-fw fa-chart-line"></i>
                    {{ 'menu.sensors' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_DeviceUtilization']"
                [ngClass]="{active: activeRoute('DeviceDetails/Utilization')}"
            >
                <a [routerLink]="['/DeviceDetails/Utilization/', deviceId]">
                    <i class="fas fa-fw fa-engine"></i>
                    {{ 'general.utilization' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_RawData']"
                [ngClass]="{active: activeRoute('DeviceDetails/RawData')}"
            >
                <a [routerLink]="['/DeviceDetails/RawData/', deviceId]">
                    <i class="fas fa-fw fa-database"></i>
                    {{ 'general.rawData' | translate }}
                </a>
            </li>
        </ul>
    </div>
</div>

<div class="col-lg-12" style="padding: 5px" *ngIf="isSettings()">
    <div class="horizontalMenuSubWrapper">
        <ul>
            <li
                *ngIf="permissions['FleetManagement_DeviceSettingsView']"
                [ngClass]="{active: activeRoute('DeviceDetails/Settings')}"
            >
                <a [routerLink]="['/DeviceDetails/Settings/', deviceId]">
                    <i class="fas fa-fw fa-random"></i>
                    {{ 'general.ioSettings' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_DeviceSettingsView']"
                [ngClass]="{active: activeRoute('DeviceDetails/Changes')}"
            >
                <a [routerLink]="['/DeviceDetails/Changes/', deviceId]">
                    <i class="fas fa-fw fa-cubes"></i>
                    {{ 'general.settingsChanges' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_OdoView']" [ngClass]="{active: activeRoute('DeviceDetails/Odo')}">
                <a [routerLink]="['/DeviceDetails/Odo/', deviceId]">
                    <i class="fas fa-fw fa-tachometer-alt"></i>
                    {{ 'general.odo' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_EngineHourView']"
                [ngClass]="{active: activeRoute('DeviceDetails/EngineHour')}"
            >
                <a [routerLink]="['/DeviceDetails/EngineHour/', deviceId]">
                    <i class="fas fa-fw fa-tachometer-alt"></i>
                    {{ 'general.engineHour' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_ImmobilizeVehicle']"
                [ngClass]="{active: activeRoute('DeviceDetails/Immobilization')}"
            >
                <a [routerLink]="['/DeviceDetails/Immobilization/', deviceId]">
                    <i class="fas fa-fw fa-scanner"></i>
                    {{ 'general.immobilization' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_Sensors']"
                [ngClass]="{active: activeRoute('DeviceDetails/SensorSettings')}"
            >
                <a [routerLink]="['/DeviceDetails/SensorSettings/', deviceId]">
                    <i class="fas fa-fw fa-sensor"></i>
                    {{ 'general.sensors' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_External']"
                [ngClass]="{active: activeRoute('DeviceDetails/External')}"
            >
                <a [routerLink]="['/DeviceDetails/External/', deviceId]">
                    <i class="fas fa-fw fa-link"></i>
                    {{ 'menu.externalApi' | translate }}
                </a>
            </li>
        </ul>
    </div>
</div>

<div class="col-lg-12" style="padding: 5px" *ngIf="isMaintenanceV2()">
    <div class="horizontalMenuSubWrapper">
        <ul>
            <li
                *ngIf="permissions['FleetManagement_DeviceMaintenance']"
                [ngClass]="{active: activeRoute('DeviceDetails/MaintenanceV2/Maintenance')}"
            >
                <a [routerLink]="['/DeviceDetails/MaintenanceV2/Maintenance', deviceId]">
                    <i class="fas fa-fw fa-car-mechanic"></i>
                    {{ 'general.maintenance' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_DeviceMaintenance']"
                [ngClass]="{active: activeRoute('DeviceDetails/MaintenanceV2/History')}"
            >
                <a [routerLink]="['/DeviceDetails/MaintenanceV2/History/', deviceId]">
                    <i class="fas fa-fw fa-screwdriver-wrench"></i>
                    {{ 'general.history' | translate }}
                </a>
            </li>
            <!-- <li
                *ngIf="permissions['FleetManagement_DeviceMaintenance']"
                [ngClass]="{active: activeRoute('DeviceDetails/Changes')}"
            >
                <a [routerLink]="['/DeviceDetails/Invoices/', deviceId]">
                    <i class="fas fa-fw fa-cubes"></i>
                    {{ 'general.settingsChanges' | translate }}
                </a>
            </li> -->
        </ul>
    </div>
</div>
<div style="clear: both"></div>
