import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "../authentication/authentication.service";
import { catchError, Observable, throwError } from "rxjs";
import { MaintenanceStatus, MaintenanceV2 } from "app/models/maintenancev2.model";

@Injectable()
export class MaintenanceV2Service {
  url = "";
  base_url = "";

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    this.url = this.authenticationService.getWebserviceURL("MaintenanceV2");
    this.base_url = this.authenticationService.getWebserviceURL("");
  }

  getPagingDueUrl(date: number) {
    return this.url + "PagingDue?date=" + date;
  }

  getPagingHistoryUrl(assetId: number, date: number) {
    return this.url + `PagingHistory?assetId=${assetId}&fromDate=${date}`;
  }

  getMaintenancesByAssetId(assetId: number): Observable<MaintenanceV2[]> {
    return this.http
      .get<MaintenanceV2[]>(this.url + "Asset/" + assetId, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  getMaintenanceStatusByAccountId(accountId: number): Observable<MaintenanceStatus> {
    return this.http
      .get<MaintenanceStatus>(this.base_url + "Account/" + accountId + "/MaintenanceStatus", {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(error);
  }
}
