<div class="col-lg-12">
    <div class='horizontalMenuWrapper'>
        <ul>
            <li [ngClass]="{'active': activeRoute('MaintenancesV2/Dashboard')}"><a href="/#/MaintenancesV2/Dashboard"><i
                        class="fas fa-fw fa-car-mechanic"></i>
                    <div>{{ 'general.dashboard' |translate }}</div>
                </a></li>
            <li [ngClass]="{'active': activeRoute('MaintenancesV2/Due')}"><a href="/#/MaintenancesV2/Due/"><i
                    class="fas fa-fw fa-car-mechanic"></i>
                <div>{{ 'general.due' |translate }}</div>
            </a></li>
            <li *ngIf="permissions['Maintenances_View']"
                [ngClass]="{active: activeRoute('MaintenancesV2/ServiceItems')}">
                <a [routerLink]="['/MaintenancesV2/ServiceItems/Overview']">
                    <i class="fas fa-fw fa-screwdriver-wrench"></i>
                    <div>{{ 'menu.serviceItems' | translate }}</div>
                </a>
            </li>
            <li [ngClass]="{'active': activeRoute('MaintenancesV2/WorkOrders/Overview')}"><a href="/#/MaintenancesV2/WorkOrders/Overview/"><i
                class="fas fa-fw fa-garage-car"></i>
            <div>{{ 'general.workOrders' |translate }}</div>
        </a></li>
        </ul>
    </div>
    <div class='horizontalMenuWrapper' style="float:right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div style="clear: both"></div>