import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { FormMode } from "app/common/enums";
import { WorkOrder, WorkOrderItem } from "app/models/workOrder.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { ServiceItemService } from "app/services/serviceitem/serviceItem.service";
import { WorkOrderService } from "app/services/workorder/workOrder.service";
import { MaintenanceWorkOrderDetailsComponent } from "app/views/shared/usercontrols/workOrderDetails.component";

@Component({
    selector: 'fh-maintenance-add-work-order',
    templateUrl: 'addWorkOrder.template.html'
})
export class MaintenanceWorkOrderAddViewComponent {
    @ViewChild(MaintenanceWorkOrderDetailsComponent, {static: true}) workOrderDetails: MaintenanceWorkOrderDetailsComponent;

    token: string;
    formMode: FormMode = FormMode.add;

    permissionName = 'Maintenances_Add';
    permissions: {};

    workOrder: WorkOrder = new WorkOrder();

    constructor(private translate: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private workOrderService: WorkOrderService, private router: Router) {
        this.workOrder.workOrderItems = [];
        this.permissions = this.authenticationService.permissions;
        this.token = this.authenticationService.getAuthToken();
    }

    onInsert(workOrder: WorkOrder) {
        this.workOrderDetails.loading = true;
    
        this.workOrderService.createWorkOrder(workOrder).subscribe(result => {
            this.workOrderDetails.success = {
            statusText: 'Success',
            success: "Work order is successfully added"
          };

          this.workOrderDetails.loading = false;
          this.cd.markForCheck();
    
        setTimeout(() => {
            this.router.navigate(['/MaintenancesV2/WorkOrders/Overview/']);
        }, 3000);
    
        }, error => {
            this.workOrderDetails,error = error
            this.workOrderDetails.loading = false;
            this.cd.markForCheck();
        });
    }
}